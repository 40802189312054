export interface MenuLinkTypes {
  title: string;
  sublinks?: MenuLinkTypes[];
  href: string;
  slug?: string;
}

export const categoryPageCategories: MenuLinkTypes[] = [
  {
    title: 'Климатизация',
    slug: 'климатизация',
    href: '/category/климатизация/',
    sublinks: [
      {
        title: 'Битова климатизация',
        href: '/category/битова-климатизация',
        slug: 'битова-климатизация',
        sublinks: [
          {
            title: 'Стенен тип',
            href: '/category/битова-климатизация-стенен-тип',
            slug: 'битова-климатизация-стенен-тип',
          },
          {
            title: 'Подови климатици',
            href: '/category/битова-климатизация-подови-климатици',
            slug: 'битова-климатизация-подови-климатици',
          },
          {
            title: 'Мултисплит системи',
            href: '/category/битова-климатизация-мултисплит-системи',
            slug: 'битова-климатизация-мултисплит-системи',
          },
        ],
      },
      {
        title: 'Търговска климатизация',
        href: '/category/търговска-климатизация',
        slug: 'търговска-климатизация',
        sublinks: [
          {
            title: 'Канални климатици',
            href: '/category/канални-климатици',
            slug: 'канални-климатици',
          },
          // {
          //   title: 'Таванни климатици',
          //   href: '/category/таванни-климатици',
          //   slug: 'таванни-климатици',
          // },
          {
            title: 'Касетъчни климатици',
            href: '/category/касетъчни-климатици',
            slug: 'касетъчни-климатици',
          },
          {
            title: 'Сървърни климатици',
            href: '/category/сървърни-климатици',
            slug: 'сървърни-климатици',
          },
        ],
      },
      // {
      //   title: 'Грижа за въздуха',
      //   href: '/category/грижа-за-въздуха',
      //   slug: 'грижа-за-въздуха',
      //   sublinks: [
      //     {
      //       title: 'Въздухопречистватели',
      //       href: '/category/въздухопречистватели',
      //       slug: 'въздухопречистватели',
      //     },
      //   ],
      // },
    ],
  },
  {
    title: 'Отопление',
    href: '/category/отопление',
    slug: 'отопление',
    sublinks: [
      {
        title: 'Термопомпени системи',
        href: '/category/отопление-термопомпени-системи',
        slug: 'отопление-термопомпени-системи',
        sublinks: [
          {
            title: 'Високотемпературни термопомпи',
            href: '/category/високотемпературни-термопомпи',
            slug: 'високотемпературни-термопомпи',
          },
          {
            title: 'Нискотемпературни термопомпи',
            href: '/category/нискотемпературни-термопомпи',
            slug: 'нискотемпературни-термопомпи',
          },
          // {
          //   title: 'Термопомпи с вграден бойлер',
          //   href: '/category/термопомпи-с-вграден-бойлер',
          //   slug: 'термопомпи-с-вграден-бойлер',
          // }
        ],
      },
      {
        title: 'Отоплителна инсталация',
        href: '/category/отоплителна-инсталация',
        slug: 'отоплителна-инсталация',
        sublinks: [
          {
            title: 'Подово отопление',
            href: '/category/подово-отопление',
            slug: 'подово-отопление',
          },
          {
            title: 'Вентилаторни конвектори',
            href: '/category/вентилаторни-конвектори',
            slug: 'вентилаторни-конвектори',
          },
          // {
          //   title: 'Електрически конвектори',
          //   href: '/category/електрически-конвектори',
          //   slug: 'електрически-конвектори',
          // },
          // {
          //   title: 'Радиатори',
          //   href: '/category/радиатори',
          //   slug: 'радиатори',
          // },
        ],
      },
      {
        title: 'Котли за отопление',
        href: '/category/котли-за-отопление',
        slug: 'котли-за-отопление',
        sublinks: [
          {
            title: 'Кондензни газови котли',
            href: '/category/кондензни-газови-котли',
            slug: 'кондензни-газови-котли',
          },
        ],
      },
    ],
  },
  {
    title: 'Марки',
    href: '/category/марки',
    slug: 'марки',
    sublinks: [
      {
        title: 'Mitsubishi Electric',
        href: '/category/марки-mitsubishi-electric',
        slug: 'марки-mitsubishi-electric',
        sublinks: [
          {
            title: 'Стенни климатици',
            href: '/category/стенни-климатици',
            slug: 'стенни-климатици',
          },
          {
            title: 'Мултисплит системи',
            href: '/category/мултисплит-системи-mitsubishi-electric',
            slug: 'мултисплит-системи-mitsubishi-electric',
          },
          {
            title: 'Подови климатици',
            href: '/category/подови-климатици-mitsubishi-electric',
            slug: 'подови-климатици-mitsubishi-electric',
          },
          // {
          //   title: 'За скрит монтаж',
          //   href: '/category/за-скрит-монтаж',
          //   slug: 'за-скрит-монтаж',
          // },
        ],
      },
      {
        title: 'Hitachi',
        href: '/category/hitachi',
        slug: 'hitachi',
        sublinks: [
          {
            title: 'Битова климатизация',
            href: '/category/битова-климатизация-hitachi',
            slug: 'битова-климатизация-hitachi',
          },
          {
            title: 'Термопомпи въздух-вода Yutaki',
            href: '/category/термопомпи-въздух-вода-yutaki',
            slug: 'термопомпи-въздух-вода-yutaki',
          },
          {
            title: 'Търговски климатизатори',
            href: '/category/търговски-климатизатори',
            slug: 'търговски-климатизатори',
          },
        ],
      },
      {
        title: 'Daikin',
        href: '/category/daikin',
        slug: 'daikin',
        sublinks: [
          {
            title: 'Битова климатизация',
            href: '/category/битова-климатизация-daikin',
            slug: 'битова-климатизация-daikin',
          },
          {
            title: 'Термопомпи въздух-вода Altherma',
            href: '/category/термопомпи-въздух-вода-altherma',
            slug: 'термопомпи-въздух-вода-altherma',
          },
        ],
      },
      {
        title: 'Gree',
        href: '/category/gree',
        slug: 'gree',
        sublinks: [
          {
            title: 'Битова климатизация',
            href: '/category/марки-gree',
            slug: 'марки-gree',
          },
          {
            title: 'Подови климатици',
            href: '/category/подови-климатици-gree',
            slug: 'подови-климатици-gree',
          },
        ],
      },
      {
        title: 'Toshiba',
        href: '/category/toshiba',
        slug: 'toshiba',
        sublinks: [
          {
            title: 'Стенен тип',
            href: '/category/стенен-тип-toshiba',
            slug: 'стенен-тип-toshiba',
          },
          {
            title: 'Подов тип',
            href: '/category/toshiba-подов-тип',
            slug: 'toshiba-подов-тип',
          },
        ],
      },
      {
        title: 'Alpin',
        href: '/category/alpin',
        slug: 'alpin',
        sublinks: [
          {
            title: 'Стенен тип',
            href: '/category/стенен-тип-alpin',
            slug: 'стенен-тип-alpin',
          },
          // {
          //   title: 'Сплит системи',
          //   href: '/category/сплит-системи-alpin',
          //   slug: 'сплит-системи-alpin',
          // },
          // {
          //   title: 'Търговски системи',
          //   href: '/category/търговски-системи-alpin',
          //   slug: 'търговски-системи-alpin',
          // },
        ],
      },
    ],
  }
];
