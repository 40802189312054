import {ShortProductType} from "../../../feature/product/types/ShortProductType";
import React, {useState} from "react";
import currencyService from "../../../services/currencyService";
import {Link} from "react-router-dom";
import {Skeleton} from "primereact/skeleton";

interface Props {
  product: ShortProductType;
}

export const SingleProduct: React.FC<Props> = ({product}) => {

  const [imageLoad, setImageLoad] = useState<boolean>(true);
  const handleImageLoad = () => {
    setImageLoad(false);
  };

  return (
    <Link to={`/product/${product.slug}`}
          className="relative border-2 border-gray-100 hover:border-gray-200 rounded hover:scale-[1.02] transition group z-10 cursor-pointer">
      <div className="absolute">
        {product.hasPromo &&
          <div className="bg-red-500 my-1 mx-1 py-0.5 px-1 text-xs rounded font-medium w-fit text-white">Промоция</div>
        }
        {product.hasFreeInstallation &&
          <div className="bg-[#2fa7e0] my-1 mx-1 py-0.5 px-1 text-xs rounded font-medium w-fit text-white">Включен монтаж</div>
        }
        {product.hasWifi &&
          <div className="bg-[#2fa7e0] my-1 mx-1 py-0.5 px-1 text-xs rounded font-medium w-fit text-white">Wi-Fi</div>
        }
      </div>
      {/*<div className="absolute right-0">*/}
      {/*  <div className="opacity-0 group-hover:opacity-100 transition duration-200 transition bg-[#66b8df] text-white*/}
      {/*    my-1 mx-1 py-0.5 px-1 text-xs font-medium w-fit rounded tracking-wide">*/}
      {/*    12000 BTU*/}
      {/*  </div>*/}
      {/*  <div className="opacity-0 group-hover:opacity-100 transition duration-200 transition bg-green-500 text-white my-1 mx-1 py-0.5 px-1 text-xs*/}
      {/*  font-medium w-fit rounded-full tracking-wide">*/}
      {/*    A+++/A++*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div>
        {imageLoad &&
          <div>
            <Skeleton height="300px"/>
          </div>
        }
        <img src={product.mainImageUrl} className="w-full" alt="" onLoad={handleImageLoad}/>
      </div>
      <div className="p-4">
        <div className="text-gray-600">
          {product.brand}
        </div>
        <div className="text-lg font-medium mt-2">
          {product.name}
        </div>
        <div className="flex justify-between mt-4">
          {product.hasPromo &&
            <div className="text-lg font-semibold my-auto">
              <span className="text-red-700">{currencyService.format(product.promoPrice)}</span>
              <span className="ml-3 text-base font-normal line-through text-gray-500">{currencyService.format(product.singlePrice)}</span>
            </div>
          }
          {!product.hasPromo &&
            <div className="text-lg font-semibold my-auto">
              <span>{currencyService.format(product.singlePrice)}</span>
            </div>
          }
          <div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default SingleProduct;
