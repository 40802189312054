import {DetailedProductType} from "../../feature/product/types/DetailedProductType";
import React from "react";
import currencyService from "../../services/currencyService";

interface Props {
  product: DetailedProductType;
  value: boolean;
  onChange: (value: boolean) => void;
}

export const ProductPageInstallation: React.FC<Props> = (
  {
    product,
    value,
    onChange
  }
) => {

  if (product.hasFreeInstallation) {
    return null;
  }

  return (
    <>
      <h2 className="mt-4 text-lg text-gray-900 font-medium mb-2">
        Желаете ли монтаж? <span className="text-red-600">*</span>
      </h2>

      <div className="flex">
        <button onClick={() => onChange(false)}
                className={`px-3 py-2 mr-2 hover:bg-sky-500 transition rounded font-bold text-gray-600 `
                  + (!value ? 'text-white bg-sky-500 hover:bg-sky-600' : 'outline outline-1 outline-gray-400 hover:text-white')}>
          Без монтаж
        </button>
        <button
          onClick={() => onChange(true)}
          className={`px-3 py-2 mr-2 hover:bg-sky-500 transition rounded font-bold text-gray-600 `
            + (value ? 'text-white bg-sky-500 hover:bg-sky-600' : 'outline outline-1 outline-gray-400 hover:text-white')}>
          Стандартен монтаж + {currencyService.format(product.installationPrice)}
        </button>
      </div>
    </>
  )
}

export default ProductPageInstallation;