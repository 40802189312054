import axios, {AxiosInstance, AxiosResponse} from "axios";

interface IApiService {
  get<T>(url: string): Promise<T>;

  post<T, R>(url: string, data?: R): Promise<T>;

  put<T, R>(url: string, data?: R): Promise<T>;

  patch<T, R>(url: string, data?: R): Promise<T>;

  delete<T>(url: string): Promise<T>;
}

const api: IApiService = (() => {
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  axiosInstance.interceptors.request.use(
    config => {
      config.headers["Content-Type"] = "application/json";
      return config;
    },
    (error: any) => {
      // console.log(error)
      // Promise.reject(error);
    }
  );

  return {
    async get<T>(url: string): Promise<T> {
      const response: AxiosResponse<T> = await axiosInstance.get(url);
      return response.data;
    },

    async post<T, R>(url: string, data: T): Promise<R> {
      const response: AxiosResponse<R> = await axiosInstance.post(url, data);
      return response.data;
    },

    async put<T, R>(url: string, data: T): Promise<R> {
      const response: AxiosResponse<R> = await axiosInstance.put(url, data);
      return response.data;
    },

    async patch<T, R>(url: string, data: T): Promise<R> {
      const response: AxiosResponse<R> = await axiosInstance.patch(url, data);
      return response.data;
    },

    async delete<T>(url: string): Promise<T> {
      const response: AxiosResponse<T> = await axiosInstance.delete(url);
      return response.data;
    },
  };
})();

export default api;
