import {DetailedProductType} from "../../feature/product/types/DetailedProductType";
import React from "react";
import {useAddCartDetail} from "../../feature/cart/hooks/useAddCartDetail";
import {AddCartDetailType} from "../../feature/cart/types/AddCartDetailType";
import {v4 as uuidv4} from "uuid";

interface Props {
  product: DetailedProductType;
  withInstallation: boolean;
  additionalWarrantyPeriod: number
}

export const ProductPageCartButton: React.FC<Props> = ({product, withInstallation, additionalWarrantyPeriod}) => {

  const getVid = () => {
    let vid = localStorage.getItem("vid");
    if (!vid) {
      vid = uuidv4();
      localStorage.setItem("vid", vid);
    }
    return vid;
  }

  const cartDetail: AddCartDetailType = {
    additionalWarrantyPeriod: additionalWarrantyPeriod,
    productSlug: product.slug,
    quantity: 1,
    vid: getVid(),
    withInstallation: withInstallation
  };

  const addDetail = useAddCartDetail(cartDetail);

  const handleAddDetail = () => {
    addDetail.mutate();
  }

  return (
    <button type="button"
            onClick={handleAddDetail}
            className="inline-flex w-full md:w-auto items-center justify-center rounded-md border-2 border-transparent bg-sky-500
                                 bg-none px-2 py-3 text-center text-base font-bold text-white transition-all
                                 duration-200 ease-in-out focus:shadow hover:bg-sky-600">
      <svg xmlns="http://www.w3.org/2000/svg"
           className="shrink-0 mr-3 h-5 w-5"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           strokeWidth="2">
        <path strokeLinecap="round"
              strokeLinejoin="round"
              d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"/>
      </svg>
      Добави в количката
    </button>
  )
}

export default ProductPageCartButton;