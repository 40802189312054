import React, {useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {FormikErrors, useFormik} from "formik";
import {Checkbox} from "primereact/checkbox";
import {DetailedProductType} from "../../feature/product/types/DetailedProductType";
import currencyService from "../../services/currencyService";
import fastCheckoutSchema from "../../feature/cart/schemas/FastCheckoutSchema";
import {useFastCheckout} from "../../feature/cart/hooks/useFastCheckout";
import {CheckoutResponseType} from "../../feature/cart/types/CheckoutResponseType";
import {Link} from "react-router-dom";

interface Props {
    product: DetailedProductType,
    withInstallation: boolean,
    additionalWarrantyPeriod: number,
}

export const ProductPageTbiApplication: React.FC<Props> = (
    {
        product,
        withInstallation,
        additionalWarrantyPeriod,
    }) => {

    const [url, setUrl] = useState<string | null>(null);

    const initialValues: any = {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        companyName: "",
        companyEik: "",
        productSlug: product.slug,
        withInstallation: withInstallation,
        additionalWarranty: additionalWarrantyPeriod
    };

    const onSubmit = () => {
        console.log(formik.values)
        mutation.mutate();
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: fastCheckoutSchema,
        onSubmit: onSubmit
    });

    useEffect(() => {
        formik.setFieldValue('withInstallation', withInstallation);
        formik.setFieldValue('additionalWarranty', additionalWarrantyPeriod);
    }, [withInstallation, additionalWarrantyPeriod, formik]);

    const onSuccess = (data: CheckoutResponseType) => {
        setUrl(data.url);

        if (data.url) {
            window.open(data.url, '_blank');
        }
    }

    const mutation = useFastCheckout(formik.values, onSuccess);

    const isFormFieldInvalid = (name: any) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getInvalidMessage = (name: any) => {
        return isFormFieldInvalid(name) ? formik.errors[name] : undefined;
    };

    const [show, setShow] = useState<boolean>(false);

    const headerTemplate = () => {
        return (
            <div className="text-orange-600">
                Вземи на изплащане с <span className="text-black">tbi</span> bank
            </div>
        )
    }

    const convertErrorsToRenderable: (error: string | FormikErrors<any> | string[] | FormikErrors<any>[] | undefined) => React.ReactNode =
        (error) => {
            if (typeof error === 'string') {
                return error;
            } else if (Array.isArray(error)) {
                // If it's an array, join the elements into a single string (or transform as required)
                return error.map((e, idx) => <div key={idx}>{convertErrorsToRenderable(e)}</div>);
            } else if (typeof error === 'object' && error !== null) {
                // If it's a FormikErrors object, you might want to extract and render the error messages.
                // This is just a simple example; adjust according to your needs:
                return Object.values(error).join(', ');
            }
            return null;
        };

    return (
        <div>
            <button type="button"
                    onClick={() => setShow(true)}
                    className="inline-flex w-full items-center justify-center rounded-md border-2 border-transparent bg-orange-500 bg-none px-5 py-3 text-center text-base
                 font-bold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-orange-600">
                <svg xmlns="http://www.w3.org/2000/svg"
                     className="shrink-0 mr-3 h-5 w-5"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke="currentColor"
                     strokeWidth="2">
                    <path strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"/>
                </svg>
                Вземи на изплащане
            </button>

            <Dialog header={headerTemplate}
                    visible={show}
                    className="w-11/12 md:w-4/6 xl:w-2/4"
                    onHide={() => setShow(false)}>
                {url &&
                    <div className="bg-orange-50 border-l-4 border-orange-400 p-4 rounded-lg shadow-md">
                        <h3 className="font-semibold mb-2 text-orange-600">Благодарим Ви за проявения интерес!</h3>
                        <p>За да завършите Вашата поръчка и да изберете опция за финансово изплащане, моля:</p>
                        <Link to={url}
                              className="text-orange-700 hover:text-orange-900 underline mt-2 block">{url}</Link>
                    </div>
                }
                {!url &&
                    <form onSubmit={formik.handleSubmit}>
                        <div className="font-semibold">Основни данни</div>
                        <div className="grid grid-cols-2 gap-3">
              <span className="p-float-label mt-5">
                <InputText id="firstName"
                           name="firstName"
                           value={formik.values.firstName}
                           className="border-t-0 w-full border-orange-600"
                           style={{boxShadow: "none"}}
                           onChange={formik.handleChange}/>
                  {isFormFieldInvalid('firstName') &&
                      <span className="text-red-600 text-xs font-semibold">
                    {convertErrorsToRenderable(getInvalidMessage('firstName'))}
                  </span>
                  }
                  <label htmlFor="username">Име</label>
              </span>
                            <span className="p-float-label mt-5">
                <InputText id="lastName"
                           name="lastName"
                           value={formik.values.lastName}
                           className="border-t-0 w-full border-orange-600"
                           style={{boxShadow: "none"}}
                           onChange={formik.handleChange}/>
                                {isFormFieldInvalid('lastName') &&
                                    <span className="text-red-600 text-xs font-semibold">
                    {convertErrorsToRenderable(getInvalidMessage('lastName'))}
                  </span>
                                }
                                <label htmlFor="lastName">Фамилия</label>
              </span>
                            <span className="p-float-label mt-3">
                <InputText id="email"
                           name="email"
                           value={formik.values.email}
                           className="border-t-0 w-full border-orange-600"
                           style={{boxShadow: "none"}}
                           onChange={formik.handleChange}/>
                                {isFormFieldInvalid('email') &&
                                    <span className="text-red-600 text-xs font-semibold">
                    {convertErrorsToRenderable(getInvalidMessage('email'))}
                  </span>
                                }
                                <label htmlFor="email">Имейл</label>
              </span>
                            <span className="p-float-label mt-3">
                <InputText id="phoneNumber"
                           name="phoneNumber"
                           value={formik.values.phoneNumber}
                           className="border-t-0 w-full border-orange-600"
                           style={{boxShadow: "none"}}
                           onChange={formik.handleChange}/>
                                {isFormFieldInvalid('phoneNumber') &&
                                    <span className="text-red-600 text-xs font-semibold">
                    {convertErrorsToRenderable(getInvalidMessage('phoneNumber'))}
                  </span>
                                }
                                <label htmlFor="phone">Телефон</label>
              </span>
                        </div>

                        <div className="flex align-items-center mt-5">
                            <Checkbox inputId="invoice"
                                      name="invoice"
                                      value="invoice"
                                      onChange={() => formik.setFieldValue("invoice", !formik.values.invoice)}
                                      checked={formik.values.invoice}/>
                            <label htmlFor="invoice" className="ml-2 font-semibold">Желаете ли фактура на фирма?</label>
                        </div>

                        {formik.values.invoice &&
                            <div className="grid grid-cols-2 gap-3 mt-3">
                <span className="p-float-label mt-3">
                  <InputText id="companyName"
                             name="companyName"
                             value={formik.values.companyName}
                             className="border-t-0 w-full border-orange-600"
                             style={{boxShadow: "none"}}
                             onChange={formik.handleChange}/>
                  <label htmlFor="companyName">Име на фирма</label>
                </span>
                                <span className="p-float-label mt-3">
                  <InputText id="companyEik"
                             name="companyEik"
                             value={formik.values.companyEik}
                             className="border-t-0 w-full border-orange-600"
                             style={{boxShadow: "none"}}
                             onChange={formik.handleChange}/>
                  <label htmlFor="companyEik">Булстат</label>
                </span>
                            </div>
                        }

                        <div className="font-semibold mt-5">
                            Преглед на поръчката
                        </div>
                        <div className="mt-4">
                            <div className="flex justify-between items-center border-b pb-2">
                                <span className="font-semibold text-lg">{product.name}</span>
                                <span
                                    className="text-gray-600">{currencyService.format(product.hasPromo ? product.promoPrice : product.singlePrice)}</span>
                            </div>
                            <table>
                                <tr>
                                    <td className="font-semibold pr-3 pt-2">Монтаж:</td>
                                    <td>{withInstallation ? currencyService.format(product.installationPrice) : 'Без монтаж'}</td>
                                </tr>
                                {additionalWarrantyPeriod > 0 && (
                                    <tr>
                                        <td className="font-semibold pr-3">Доп. гаранция:</td>
                                        <td>{currencyService.format(additionalWarrantyPeriod * product.additionalWarrantyPrice)} ({additionalWarrantyPeriod} г.)</td>
                                    </tr>
                                )}
                                <tr>
                                    <td className="font-semibold pr-3 pb-2">Доставка:</td>
                                    <td>{currencyService.format(20)}</td>
                                </tr>
                                <tr className="border-t">
                                    <td className="font-semibold pr-3 py-2">Общо:</td>
                                    <td className="py-2">{currencyService.format(
                                        (product.hasPromo ? product.promoPrice : product.singlePrice)
                                        + (withInstallation ? product.installationPrice : 0)
                                        + (additionalWarrantyPeriod * product.additionalWarrantyPrice)
                                        + (20))}
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <button type={"submit"}
                                className="inline-flex w-full items-center justify-center rounded-md border-2 border-transparent bg-orange-500 bg-none px-5 py-3 text-center text-base
                 font-bold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-orange-600 mt-3">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className="shrink-0 mr-3 h-5 w-5"
                                 fill="none"
                                 viewBox="0 0 24 24"
                                 stroke="currentColor"
                                 strokeWidth="2">
                                <path strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"/>
                            </svg>
                            Продължи с избора на схема
                        </button>
                    </form>
                }
            </Dialog>
        </div>
    )
}

export default ProductPageTbiApplication;