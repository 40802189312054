import React from "react";
import {Link} from "react-router-dom";

interface Props {
  label: string;
}

export const NavbarItem: React.FC<Props> = ({label}) => {
  return (
    <Link to="/" className="px-5 py-4 hover:bg-[#2fa7e0] hover:text-white text-md tracking-wide uppercase font-semibold">{label}</Link>
  )
}

export default NavbarItem;