import {createStore} from "../../../lib/store";
import {CartShortInfoType} from "../types/CartShortInfoType";
import {QueryClient} from "react-query";
import {QUERY_KEYS} from "../../../data/query-keys";
import cartService from "../service/cartService";
import {CartDetailType} from "../types/CartDetailType";

const queryClient = new QueryClient();

export interface ICartStore {
  cartOpen: boolean;
  setCartOpen: (value: boolean) => void;
  cartShortInfo: CartShortInfoType;
  setCartShortInfo: (data: CartShortInfoType) => void;
  cartDetails: CartDetailType[];
  setCartDetails: (data: CartDetailType[]) => void;
  refetchCartInfo: () => void;
}

export const useCartStore = createStore<ICartStore>("cart_store", (set, get) => ({
    cartOpen: false,
    setCartOpen: (value: boolean) => set(() => ({cartOpen: value})),
    cartShortInfo: {totalPrice: 0, products: 0},
    setCartShortInfo: (data) => set(() => ({cartShortInfo: data})),
    cartDetails: [],
    setCartDetails: () => console.log,
    refetchCartInfo: async () => {
      const data = await queryClient.fetchQuery(QUERY_KEYS.GET_CART_SHORT_INFO, cartService.shortInfo);
      const details = await queryClient.fetchQuery(QUERY_KEYS.GET_CART_DETAILS, cartService.cartDetails);
      set(() => ({cartShortInfo: data}));
      set(() => ({cartDetails: details}));
    }
  })
)