import Spinner from "../../../components/Spinner";
import SingleProduct from "../../../layouts/components/product/SingleProduct";
import {useGetProductsByCategory} from "../hooks/useGetProductsByCategory";
import React from "react";

interface Props {
  slug: string;
}

export const CategoryProducts: React.FC<Props> = ({slug}) => {

  const {data, isLoading, isError} = useGetProductsByCategory(slug);

  if (isLoading || isError || !data) {
    return <Spinner/>
  }

  return (
    <div className="relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      {data.map(e => <SingleProduct key={e.slug} product={e}/>)}
    </div>
  );
}

export default CategoryProducts;