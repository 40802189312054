import {RouteObject} from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import Home from "./pages/Home";
import ProductPage from "./pages/ProductPage";
import CategoryPage from "./pages/CategoryPage";
import Checkout from "./pages/Checkout";
import Installation from "./pages/info-page/Installation";
import LoanInfo from "./pages/info-page/LoanInfo";
import PolicyInfo from "./pages/info-page/PolicyInfo";
import PaymentMethods from "./pages/info-page/PaymentMethods";

export const routes: RouteObject[] = [
  {
    element: <MainLayout/>,
    children: [
      {
        path: "/", element: <Home/>,
      },
      {
        path: "/product/:slug", element: <ProductPage/>
      },
      {
        path: "/category/:slug", element: <CategoryPage/>
      },
      {
        path: "/checkout", element: <Checkout/>
      },
      {
        path: "/montaj-na-klimatici", element: <Installation/>
      },
      {
        path: "/na-izplashtane", element: <LoanInfo/>
      },
      {
        path: "/politika", element: <PolicyInfo/>
      },
      {
        path: "/nachini-na-plashtane", element: <PaymentMethods/>
      },
      {
        path: "*", element: "Page not found"
      }
    ],
  },
];
