export const categories = [
  {
    type: "MEGA_ITEM",
    label: "Климатизация",
    children: [
      {
        label: "Битова климатизация",
        slug: 'битова-климатизация',
        children: [
          {
            label: "Стенен тип",
            slug: 'битова-климатизация-стенен-тип',
          },
          {
            label: "Подови климатици",
            slug: 'битова-климатизация-подови-климатици',
          },
          {
            label: "Мултисплит системи",
            slug: 'битова-климатизация-мултисплит-системи',
          }
        ]
      },
      {
        label: "Търговска климатизация",
        slug: 'търговска-климатизация',
        children: [
          {
            label: "Канални климатици",
            slug: 'канални-климатици',
          },
          // {
          //   label: "Таванни климатици"
          // },
          {
            label: "Касетъчни климатици",
            slug: 'касетъчни-климатици',
          },
          {
            label: "Сървърни климатици",
            slug: 'сървърни-климатици',
          },
        ]
      },
      // {
      //   label: "Грижа за въздуха",
      //   children: [
      //     {
      //       label: "Въздухопречистватели"
      //     }
      //   ]
      // }
    ]
  },
  {
    type: "MEGA_ITEM",
    label: "Отопление",
    slug: 'отопление',
    children: [
      {
        label: "Термопомпени системи",
        slug: 'отопление-термопомпени-системи',
        children: [
          {
            label: "Високотемпературни термопомпи",
            slug: "високотемпературни-термопомпи"
          },
          {
            label: "Нискотемпературни термопомпи",
            slug: 'нискотемпературни-термопомпи',
          },
          // {
          //   label: "Термопомпи с вграден бойлер"
          // }
        ]
      },
      {
        label: "Отоплителна инсталация",
        slug: 'отоплителна-инсталация',
        children: [
          {
            label: "Подово отопление",
            slug: 'подово-отопление',
          },
          {
            label: "Вентилаторни конвектори",
            slug: 'вентилаторни-конвектори',
          },
          // {
          //   label: "Електрически конвектори"
          // },
          // {
          //   label: "Радиатори"
          // }
        ]
      },
      {
        label: "Котли за отопление",
        slug: 'котли-за-отопление',
        children: [
          {
            label: "Кондензни газови котли",
            slug: 'кондензни-газови-котли',
          }
        ]
      }
    ]
  },
  {
    type: "MEGA_ITEM",
    label: "Марки",
    slug: 'марки',
    children: [
      {
        label: "Mitsubishi Electric",
        slug: 'марки-mitsubishi-electric',
        children: [
          {
            label: "Стенни климатици",
            slug: 'стенни-климатици',
          },
          {
            label: "Мултисплит системи",
            slug: 'мултисплит-системи-mitsubishi-electric',
          },
          {
            label: "Подови климатици",
            slug: 'подови-климатици-mitsubishi-electric',
          },
          // {
          //   label: "Четиристрийни касети"
          // },
          // {
          //   label: "За скрит монтаж"
          // }
        ]
      },
      {
        label: "Hitachi",
        slug: 'hitachi',
        children: [
          {
            label: "Битова климатизация",
            slug: 'битова-климатизация-hitachi',
          },
          {
            label: "Термопомпи въздух-вода Yutaki",
            slug: 'термопомпи-въздух-вода-yutaki',
          },
          {
            label: "Търговски климатизатори",
            slug: 'търговски-климатизатори',
          }
        ]
      },
      {
        label: "Daikin",
        slug: 'daikin',
        children: [
          {
            label: "Битова климатизация",
            slug: 'битова-климатизация-daikin',
          },
          {
            label: "Термопомпи въздух-вода Altherma",
            slug: 'термопомпи-въздух-вода-altherma',
          }
        ]
      },
      {
        label: "Gree",
        children: [
          {
            label: "Битова климатизация",
            slug: 'марки-gree',
          },
          {
            label: "Подови климатици",
            slug: 'подови-климатици-gree',
          }
        ]
      },
      {
        label: "Toshiba",
        slug: 'toshiba',
        children: [
          {
            label: "Стенен тип",
            slug: 'стенен-тип-toshiba',
          },
          {
            label: "Подов тип",
            slug: 'toshiba-подов-тип',
          }
        ]
      },
      {
        label: "Alpin",
        slug: 'alpin',
        children: [
          {
            label: "Стенен тип",
            slug: 'стенен-тип-alpin',
          },
          // {
          //   label: "Сплит системи"
          // },
          // {
          //   label: "Сплит системи"
          // }
        ]
      }
    ]
  },
  {
    type: "DROPDOWN",
    label: "Услуги",
    children: [
      {
        label: "Монтаж на климатици",
        href: "montaj-na-klimatici",
      },
      {
        label: "Плащане на вноски чрез кредит",
        href: "na-izplashtane"
      }
    ]
  },
  {
    type: "DROPDOWN",
    label: "Помощен център",
    children: [
      {
        label: "Начини на плащане",
        href: "nachini-na-plashtane",
      },
      {
        label: "Политика за защита на личните данни",
        href: "politika"
      }
    ]
  }
]
