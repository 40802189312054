import React from 'react';

const PolicyInfo = () => {
  return (
    <div className="leading-8">
      <h3 className="md:text-4xl font-black tracking-wider text-center pb-5">Политика за поверителност и защита на личните данни</h3>
      <p>
        <strong>Въведение:</strong> Ние, собствениците и операторите на YELSCLIMA.COM, придаваме голямо значение на защитата на вашите лични данни. Тази политика за поверителност обяснява каква информация събираме, как я използваме и как я защитаваме, когато посещавате и използвате нашия уебсайт, свързан с продажбата на климатици.
      </p>
      <p>
        <strong>Събиране на лични данни:</strong> Когато посещавате нашия уебсайт и извършвате покупки на климатици, може да събираме определена лична информация от вас. Това може да включва вашето име, адрес, електронна поща, телефонен номер, предпочитания за доставка и плащане, както и информация, свързана със сделките, които сте извършили на нашия сайт.
      </p>
      <p>
        <strong>Използване на личните данни:</strong> Събраната лична информация се използва за осъществяване на вашите поръчки, управление на вашата акаунтна информация, предоставяне на поддръжка и отговор на вашите въпроси и запитвания. Тази информация може да се използва и за улесняване на бъдещи покупки и комуникация с вас относно нови продукти, промоции или специални оферти, ако сте дали съгласие за такава комуникация.
      </p>
      <p>
        <strong>Защита на личните данни:</strong> Ние предприемаме разумни технически и организационни мерки за защита на вашите лични данни от неоторизиран достъп, загуба, злоупотреба или разкриване. Информацията се съхранява на сигурни сървъри и се използва само въз основа на правомерни цели.
      </p>
      <p>
        <strong>Споделяне на личните данни:</strong> Не продаваме, разменяме или предаваме вашите лични данни на трети страни, освен ако това не е необходимо за изпълнението на вашата поръчка или ако сме задължени да го направим съгласно приложимото законодателство или по решение на компетентен орган.
      </p>
      <p>
        <strong>Бисквитки и подобни технологии:</strong> Използваме бисквитки и подобни технологии, които ни помагат да подобрим функционалността на нашия уебсайт и да предоставим по-персонализирано преживяване за нашите потребители. Повече информация относно използването на бисквитки можете да намерите в нашата Политика за бисквитките.
      </p>
      <p>
        <strong>Вашите права:</strong> Вие имате право да поискате достъп до личната си информация, която съхраняваме, и да я актуализирате или коригирате при необходимост. Също така, можете да поискате да прекратим използването или изтриването на вашите лични данни, освен ако не сме задължени да ги съхраняваме поради законови изисквания или за целите на изпълнението на сключените с вас договори.
      </p>
      <p>
        <strong>Промени в политиката:</strong> Ние запазваме правото си да променяме или актуализираме тази политика за поверителност по всяко време. Всяка промяна ще бъде публикувана на нашия уебсайт и ще влезе в сила веднага след публикуването й.
      </p>
      <p>
        Ако имате въпроси относно нашата политика за поверителност или начина, по който обработваме вашата информация, моля, свържете се с нас чрез контактната информация, предоставена на нашия уебсайт.
      </p>
      <p>
        <strong>Фирма: НСВ ЕООД</strong><br />
        <strong>МОЛ: Николай Вучков</strong><br />
        <strong>Адрес: ж.к. Левски Г 15 вх. Г</strong>
      </p>
    </div>
  );
}

export default PolicyInfo;
