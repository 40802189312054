import React from "react";
import {categoryPageCategories} from "../../data/category-page-categories";
import ExpandableItems from "../../components/ExpandableItems";
import {useParams} from "react-router-dom";

export const CategoryPageFilters = () => {

  const {slug} = useParams();

  return (
    <div className="bg-white">
      {categoryPageCategories.map((link) => (
        <ExpandableItems
          key={link.title}
          href={link.href}
          title={link.title}
          links={link.sublinks || []}
          slug={link.slug!}
          currentPageParam={slug!}/>
      ))}
    </div>
  );
}

export default CategoryPageFilters;