import {ProgressSpinner} from "primereact/progressspinner";

export const Spinner = () => {
  return (
    <div className="flex w-full justify-center">
      <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="1" fill="var(--surface-ground)" animationDuration=".5s"/>
    </div>
  )
}

export default Spinner;