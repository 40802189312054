import {useGetCartDetails} from "../feature/cart/hooks/useGetCartDetails";
import currencyService from "../services/currencyService";
import Spinner from "../components/Spinner";
import {InputLabelIcon} from "../components/InputLabelIcon";
import {faCity, faEnvelope, faIdCard, faPhone, faSignsPost} from "@fortawesome/free-solid-svg-icons";
import {CheckoutType} from "../feature/cart/types/CheckoutType";
import {useFormik} from "formik";
import checkoutSchema from "../feature/cart/schemas/CheckoutSchema";
import {useCheckout} from "../feature/cart/hooks/useCheckout";
import FlagBulgaria from "../assets/Flag_of_Bulgaria.svg.png"
import React, {useState} from "react";
import MethodBox from "../components/MethodBox";
import {useCartStore} from "../feature/cart/store/cartStore";
import {CheckoutResponseType} from "../feature/cart/types/CheckoutResponseType";
import {useQueryClient} from "react-query";
import {QUERY_KEYS} from "../data/query-keys";
import {Link} from "react-router-dom";

export const Checkout = () => {

  const queryClient = useQueryClient();

  const {data: cartDetailsData, isLoading: cartDetailsIsLoading, isError: cartDetailsIsError} = useGetCartDetails();

  const cartInfo = useCartStore(state => state.cartShortInfo);
  const refetchCartInfo = useCartStore(state => state.refetchCartInfo);

  const [isBusinessInvoice, setIsBusinessInvoice] = useState(false);
  const [checkoutData, setCheckoutData] = useState<CheckoutResponseType | null>(null);

  const initialValues: CheckoutType = {
    firstName: "",
    secondName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    city: "",
    region: "",
    businessName: "",
    businessBulstat: "",
    postCode: "",
    deliveryAddress: "",
    paymentType: "CASH_ON_DELIVERY",
    visitorId: localStorage.getItem("vid")!
  };

  const onSubmit = () => {
    checkout.mutate();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: checkoutSchema,
    onSubmit: onSubmit
  });

  const onSuccess = (data: CheckoutResponseType) => {
    refetchCartInfo();
    setCheckoutData(data);

    if (data.url) {
      window.open(data.url, '_blank');
    }

    queryClient.refetchQueries(QUERY_KEYS.GET_CART_DETAILS);
    queryClient.refetchQueries(QUERY_KEYS.GET_CART_SHORT_INFO);
  }

  const checkout = useCheckout(formik.values, onSuccess);

  if (cartDetailsIsLoading || cartDetailsIsError) {
    return (
      <Spinner/>
    )
  }

  const isFormFieldInvalid = (name: keyof typeof formik.touched) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getInvalidMessage = (name: keyof typeof formik.touched) => {
    return isFormFieldInvalid(name) ? formik.errors[name] : undefined;
  };

  if (checkoutData) {
    return (
      <div className="py-14 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto">
        <div className=" mx-auto bg-white p-8 shadow-md">
          <h2 className="text-2xl font-bold mb-4">Благодарим Ви за поръчката!</h2>
          <p className="mb-6">
            {!checkoutData.url
              &&
              <span>
                Вашата поръчка беше успешно направена със следния идентификатор на поръчката:
              </span>
            }
          </p>
          {checkoutData.url &&
            <p className="text-lg bg-orange-100 px-4 py-2 rounded-md mb-4">
              <div>
                Ако прозорецът, водещ към <span className="text-[#ff6600] font-bold">tbi</span> <span className="font-bold">bank</span>, не се отвори автоматично, моля,
                натиснете тук, за да продължите поръчката си!
                <div className="text-xs block mt-3 font-bold">
                  <Link to={checkoutData.url}>{checkoutData.url}</Link>
                </div>
              </div>
            </p>
          }
          {!checkoutData.url &&
            <p className="text-lg bg-green-100 px-4 py-2 rounded-md mb-4 font-bold">
              Номер на поръчка: {checkoutData.orderNo}
            </p>
          }
          <p className="mb-4">
            Оценяваме вашето доверие и скоро ще се свържем с вас с допълнителни детайли относно поръчката ви.
          </p>
          <p className="text-gray-500">
            Ако имате въпроси или нужда от помощ, не се колебайте да се свържете с нашия отдел за обслужване на клиенти.
          </p>
        </div>
      </div>
    )
  }


  if (!cartDetailsData || cartDetailsData.length === 0) {
    return (
      <div className="font-medium text-xl tracking-wider text-center">
        Вашата количка е празна
      </div>
    )
  }

  const totalQuantity = cartDetailsData.reduce((total, item) => {
    total += item.quantity;
    return total;
  }, 0)

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="text-center md:text-3xl text-3xl mt-5">
        <div className="relative">
          <div className="inline-block">
            <div className="md:text-4xl font-black tracking-wider">Плащане</div>
            <hr className="border-2 border-sky-500 mt-2 w-auto"/>
          </div>
        </div>
      </div>
      <div className="container mx-auto grid  lg:grid-cols-2">
        <div className="px-4 pt-8">
          <p className="text-xl font-medium">Резюме на поръчката</p>
          <p className="text-gray-400">Провери продуктите, след което избери метод на плащане</p>

          <div className="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6 overflow-y-auto max-h-96">
            {cartDetailsData.slice(0, 5).map((item) => (

              <div className="flex flex-col rounded-lg bg-white sm:flex-row" key={item.id}>
                <img
                  className="m-2 h-24 w-28 rounded-md border object-cover object-center"
                  src={item.productMainImageUrl}
                  alt={item.productName}
                />
                <div className="flex w-full flex-col px-4 py-4">
                  <span className="font-semibold">{item.productName}</span>
                  <span className="float-right text-gray-400">Цена: {currencyService.format(item.basePrice)}</span>
                  <span className="float-right text-gray-400">Количество: {item.quantity}</span>
                  {/*<span*/}
                  {/*  className="float-right text-gray-400">Доставка: {item.quantity} x {currencyService.format(20)} ({currencyService.format(item.quantity * 20)})</span>*/}
                  {item.withInstallation &&
                    <span className="float-right text-gray-400">
                      Монтаж: {item.quantity} x {currencyService.format(item.installationPrice)} ({currencyService.format(item.quantity * item.installationPrice)})
                    </span>
                  }
                  {item.additionalWarrantyPeriod !== 0 &&
                    <span className="float-right text-gray-400">
                      Доп.
                      гаранция: {item.quantity} x {currencyService.format(item.additionalWarrantyPeriod * item.additionalWarrantyPrice)} ({item.additionalWarrantyPeriod}г.)
                    </span>
                  }
                  <span className="font-semibold text-gray-600 text-lg mt-1">Общо: {currencyService.format(item.totalPrice)}</span>
                </div>
              </div>
            ))}
          </div>

          <p className="mt-8 text-lg font-medium">Методи на плащане</p>
          <form className="mt-5 grid gap-6">
            {/*<MethodBox id="1"*/}
            {/*           label="Банков превод"*/}
            {/*           onClick={() => setPaymentType("BANK_TRANSFER")}*/}
            {/*           description="Платете чрез банков превод дрн дрън"/>*/}
            {/*<MethodBox id="2"*/}
            {/*           label="Плащане с карта"*/}
            {/*           onClick={() => setPaymentType("CARD")}*/}
            {/*           description="Платете чрез банков превод дрн дрън"/>*/}
            <MethodBox id="3"
                       label="Купи на изплащане с tbi bank"
                       onClick={() => formik.setFieldValue("paymentType", "TBI_PAY")}
                       checked={formik.values.paymentType === "TBI_PAY"}
                       description="Вземете на изплащане през tbi bank"/>
            <MethodBox id="4"
                       label="Наложен платеж"
                       checked={formik.values.paymentType === "CASH_ON_DELIVERY"}
                       onClick={() => formik.setFieldValue("paymentType", "CASH_ON_DELIVERY")}
                       description="Платете при получаване на пратката"/>
          </form>

        </div>

        <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
          <p className="text-xl font-medium">Детайли</p>
          <p className="text-gray-400">Завършете поръчката си, като предоставите данните си.</p>
          <div className="">
            <div className="pt-5 font-medium"><span className="text-lg">1. Лични данни</span></div>

            <div className="flex w-full gap-2">
              <InputLabelIcon
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                id="firstName"
                label="Име *"
                placeholder="Иван"
                invalid={isFormFieldInvalid("firstName")}
                invalidMessage={getInvalidMessage("firstName")}
                icon={faIdCard}
              />
              <InputLabelIcon
                name="secondName"
                value={formik.values.secondName}
                onChange={formik.handleChange}
                id="secondName"
                label="Презиме"
                placeholder="Иванов"
                invalid={isFormFieldInvalid("secondName")}
                invalidMessage={getInvalidMessage("secondName")}
                icon={faIdCard}
              />
            </div>

            <div className="flex w-full gap-2">
              <InputLabelIcon
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                id="lastName"
                label="Фамилия *"
                placeholder="Петров"
                invalid={isFormFieldInvalid("lastName")}
                invalidMessage={getInvalidMessage("lastName")}
                icon={faIdCard}
              />
              <InputLabelIcon
                name="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                id="phoneNumber"
                label="Телефонен номер *"
                placeholder="пример: 0893363628"
                invalid={isFormFieldInvalid("phoneNumber")}
                invalidMessage={getInvalidMessage("phoneNumber")}
                icon={faPhone}
              />
            </div>

            <InputLabelIcon
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              id="email"
              label="Е-майл *"
              placeholder="пример: yelsclima@gmail.com"
              invalid={isFormFieldInvalid("email")}
              invalidMessage={getInvalidMessage("email")}
              icon={faEnvelope}
            />

            <div className="pt-5 font-medium"><span className="text-lg">Адрес на получател</span></div>
            <div className="flex w-full gap-2">
              <InputLabelIcon
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                id="city"
                label="Град *"
                placeholder="пример: София"
                invalid={isFormFieldInvalid("city")}
                invalidMessage={getInvalidMessage("city")}
                icon={faCity}
              />
              <InputLabelIcon
                name="region"
                value={formik.values.region}
                onChange={formik.handleChange}
                id="region"
                label="Област *"
                placeholder="пример: София-град"
                invalid={isFormFieldInvalid("region")}
                invalidMessage={getInvalidMessage("region")}
                icon={faCity}
              />
            </div>
            <div className="flex w-full gap-2">
              <InputLabelIcon
                name="postCode"
                value={formik.values.postCode}
                onChange={formik.handleChange}
                id="postCode"
                label="Пощенски код *"
                placeholder="пример: 1000"
                invalid={isFormFieldInvalid("postCode")}
                invalidMessage={getInvalidMessage("postCode")}
                icon={faSignsPost}
              />
            </div>

            <div className="w-full gap-2">
              <label htmlFor="billing-address" className="mt-4 mb-2 block text-lg font-medium">Адрес за доставка *</label>
              <div className="w-full flex">
                <div className="relative flex-grow sm:flex-shrink-0 sm:w-7/12">
                  <input
                    name="deliveryAddress"
                    value={formik.values.deliveryAddress}
                    onChange={formik.handleChange}
                    type="text"
                    id="billing-address"
                    className={`${getInvalidMessage("deliveryAddress") ? 'border-red-400' : 'border-gray-200'} w-full rounded-md border px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500`}
                    placeholder="Адрес за доставка или офис на Еконт"
                  />
                  <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                    <img className="h-4 w-4 object-contain" src={FlagBulgaria} alt="BG flag"/>
                  </div>
                </div>
              </div>
              {
                getInvalidMessage("deliveryAddress") &&
                <div className="text-sm text-red-600 ml-1 block w-full">{getInvalidMessage("deliveryAddress")}</div>
              }
            </div>

            <div className="flex items-center w-full gap-2 ">
              <label htmlFor="business-invoice" className="mt-4 mb-2 block text-lg font-medium">
                Желаете ли фактура?
              </label>
              <input
                type="checkbox"
                id="business-invoice"
                checked={isBusinessInvoice}
                onChange={(e) => setIsBusinessInvoice(e.target.checked)}
              />
            </div>

            {isBusinessInvoice && (
              <>
                <InputLabelIcon
                  name="businessName"
                  value={formik.values.businessName}
                  onChange={formik.handleChange}
                  id="business-name"
                  label="Фирма"
                  placeholder="..."
                  icon={faIdCard}
                />
                <InputLabelIcon
                  name="businessBulstat"
                  value={formik.values.businessBulstat}
                  onChange={formik.handleChange}
                  id="business-bulstat"
                  label="ЕИК / ДДС Номер"
                  placeholder="..."
                  icon={faIdCard}
                />
              </>
            )}

            <div className="mt-6 border-t border-b py-2">
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">Междинна сума</p>
                <p className="font-semibold text-gray-900">
                  {cartInfo?.totalPrice && currencyService.format(cartInfo.totalPrice)}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">Доставка</p>
                <p className="font-semibold text-gray-900">{currencyService.format(20 * totalQuantity)}</p>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">Обща сума</p>
              <p className="text-2xl font-semibold text-gray-900">
                {cartInfo?.totalPrice && currencyService.format(cartInfo.totalPrice + (20 * totalQuantity) + (cartDetailsData.reduce((total, item) => {
                  return total;
                }, 0)))}
              </p>
            </div>
          </div>
          <button className="mt-4 mb-8 w-full rounded-md bg-sky-500 px-6 py-3 font-medium text-white"
                  type={"submit"}>
            Продължи
          </button>
        </div>
      </div>
    </form>
  );
}

export default Checkout;