import {useCustomQuery} from "../../../lib/query";
import {QUERY_KEYS} from "../../../data/query-keys";
import productService from "../service/productService";

export const useGetSearchItems = () => {
  const {data, isLoading, isError} = useCustomQuery({
    key: QUERY_KEYS.GET_SEARCH_ITEMS,
    query: () => productService.getSearchItems()
  });

  if (isLoading || isError || !data) {
    return {isLoading, isError};
  }

  return {data, isLoading, isError};
}