import React from 'react';
import PricingCard from "../../components/PricingCard";

const InstallationInfo: React.FC = () => {
  return (
    <div className="p-10">

      <h3 className="md:text-4xl font-black tracking-wider text-center pb-5">Професионален монтаж</h3>
      <p>
        <b>Yelsclima</b> представлява високо професионална методология, която гарантира, че всеки монтаж е
                         извършен с внимание към всеки детайл. Нашите екипи следват строги процедури и стандарти, които
                         гарантират оптимално функциониране на климатичната техника. Със специализираното си обучение и знания,
                         нашите експерти са в състояние да преодоляват предизвикателствата, които възникват по време на монтажа,
                         и да гарантират най-доброто възможно резултат.
      </p>

      <div className="pt-10 flex flex-col md:flex-row gap-3">
        <PricingCard
          title="СТАНДАРТЕН МОНТАЖ ДО 3 Л.М. ТРЪБЕН ПЪТ"
          subtitle="7 - 14 000 BTU"
          price="280 лв."
          features={[
            "Качване на климатик и материали с асансьор",
            "Фиксиране на стойка на вътрешно тяло",
            "Технологичен отвор в стената 1 бр.",
            "Медни тръби с термо изолация до 3 л. м.",
            "Отводняваща гофрирана тръба до 3 л. м.",
            "Комуникационен кабел до 5 л. м.",
            "Щепсел и захранващ кабел до 4 л.м.",
            "Поставяне на PVC канал до 1 л. м.",
            "Стойки за монтиране на външно тяло",
            "Свързване на двете климатични тела",
            "Вакуумиране",
            "Пуск на машината",
            "Кратък инструктаж на клиента за експлоатация",
          ]}
        />
        <PricingCard
          title="СТАНДАРТЕН МОНТАЖ ДО 3 Л.М. ТРЪБЕН ПЪТ"
          subtitle="15 - 24 000 BTU"
          price="320 лв."
          features={[
            "Качване на климатик и материали с асансьор",
            "Фиксиране на стойка на вътрешно тяло",
            "Технологичен отвор в стената 1 бр.",
            "Медни тръби с термо изолация до 3 л. м.",
            "Отводняваща гофрирана тръба до 3 л. м.",
            "Комуникационен кабел до 5 л. м.",
            "Щепсел и захранващ кабел до 4 л.м.",
            "Поставяне на PVC канал до 1 л. м.",
            "Стойки за монтиране на външно тяло",
            "Свързване на двете климатични тела",
            "Вакуумиране",
            "Пуск на машината",
            "Кратък инструктаж на клиента за експлоатация",
          ]}
        />
        <PricingCard
          title="СТАНДАРТЕН МОНТАЖ ДО 3 Л.М. ТРЪБЕН ПЪТ"
          subtitle="30 - 55 000 BTU"
          price="399 лв."
          features={[
            "Качване на климатик и материали с асансьор",
            "Фиксиране на стойка на вътрешно тяло",
            "Технологичен отвор в стената 1 бр.",
            "Медни тръби с термо изолация до 3 л. м.",
            "Отводняваща гофрирана тръба до 3 л. м.",
            "Комуникационен кабел до 5 л. м.",
            "Щепсел и захранващ кабел до 4 л.м.",
            "Поставяне на PVC канал до 1 л. м.",
            "Стойки за монтиране на външно тяло",
            "Свързване на двете климатични тела",
            "Вакуумиране",
            "Пуск на машината",
            "Кратък инструктаж на клиента за експлоатация",
          ]}
        />
      </div>

      <div className="py-10 ">
        <h2 className="text-3xl font-bold mb-6">Ценоразпис</h2>

        <table className="w-full border-collapse">
          <thead>
          <tr>
            <th className="border border-gray-400 px-4 py-2">УСЛУГА</th>
            <th className="border border-gray-400 px-4 py-2">ЦЕНА</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Монтаж на външно тяло на мултисплит при наличие
                                                             на
                                                             тръбен път
            </td>
            <td className="border border-gray-400 px-4 py-2">150 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Монтаж на вътрешно тяло 7 - 14 000 BTU на
                                                             мултисплит при наличие на тръбен път
            </td>
            <td className="border border-gray-400 px-4 py-2">70 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Монтаж на вътрешно тяло 15 - 24 000 BTU на
                                                             мултисплит при наличие на тръбен път
            </td>
            <td className="border border-gray-400 px-4 py-2">100 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Допълнителен тръбен път за климатик 7 - 14 000
                                                             BTU
                                                             на л.м.
            </td>
            <td className="border border-gray-400 px-4 py-2">45 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Допълнителен тръбен път за климатик 15 - 24 000
                                                             BTU
                                                             на л.м.
            </td>
            <td className="border border-gray-400 px-4 py-2">55 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Допълнителен тръбен път за климатик 30 - 55 000
                                                             BTU
                                                             на л.м.
            </td>
            <td className="border border-gray-400 px-4 py-2">70 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Вкопаване на тръбен път в тухлена зидария на
                                                             л.м.
            </td>
            <td className="border border-gray-400 px-4 py-2">35 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Вкопаване на тръбен път в бетон на л.м. (с
                                                             изключение на панелни блокове, след оглед)
            </td>
            <td className="border border-gray-400 px-4 py-2">75 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Качване на климатик, материали и инструменти
                                                             без
                                                             асансьор 7 - 14 000 BTU (на етаж)
            </td>
            <td className="border border-gray-400 px-4 py-2">10 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Качване на климатик, материали и инструменти
                                                             без
                                                             асансьор 15 - 24 000 BTU (на етаж)
            </td>
            <td className="border border-gray-400 px-4 py-2">15 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Монтаж на климатик на 2 етапа</td>
            <td className="border border-gray-400 px-4 py-2">80 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Пробиване на втори технологичен отвор за тръбен
                                                             път
            </td>
            <td className="border border-gray-400 px-4 py-2">30 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Тест с азот на тръбен път</td>
            <td className="border border-gray-400 px-4 py-2">50 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Заваряване на медна тръба</td>
            <td className="border border-gray-400 px-4 py-2">15 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Антивибрационни тампони (к-кт)</td>
            <td className="border border-gray-400 px-4 py-2">40 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">PVC кондензна вана с включен монтаж</td>
            <td className="border border-gray-400 px-4 py-2">80 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">PVC кондензна вана, нагревател и термостат с
                                                             включен монтаж
            </td>
            <td className="border border-gray-400 px-4 py-2">170 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Метална кондензна вана с включен монтаж, малка
                                                             /
                                                             голяма
            </td>
            <td className="border border-gray-400 px-4 py-2">100/120 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Метална кондензна вана, нагревател и термостат
                                                             с
                                                             включен монтаж, малка / голяма
            </td>
            <td className="border border-gray-400 px-4 py-2">200/220 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Допълнително гофрирана тръба 1 л.м.</td>
            <td className="border border-gray-400 px-4 py-2">6 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Допълнителен PVC канал 15х15 мм. на л.м.</td>
            <td className="border border-gray-400 px-4 py-2">10 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Допълнителен PVC канал 60х60 мм. на л.м.</td>
            <td className="border border-gray-400 px-4 py-2">15 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Допълнителен кабел за комуникация 1 л.м. 4 х
                                                             1.5
                                                             мм
            </td>
            <td className="border border-gray-400 px-4 py-2">6 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Допълнителен захранващ кабел 1 л.м. 3 х 1.5
                                                             мм
            </td>
            <td className="border border-gray-400 px-4 py-2">6 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Допълнителен захранващ кабел 1 л.м. 3 х 2.5
                                                             мм
            </td>
            <td className="border border-gray-400 px-4 py-2">7 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Вкопаване на захранващ кабел в тухлена зидария
                                                             на
                                                             л.м.
            </td>
            <td className="border border-gray-400 px-4 py-2">15 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Вкопаване на захранващ кабел в бетон на л.м. (с
                                                             изключение на панелни блокове, след оглед)
            </td>
            <td className="border border-gray-400 px-4 py-2">30 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Вкопаване на комуникационен кабел в тухлена
                                                             зидария
                                                             на л.м.
            </td>
            <td className="border border-gray-400 px-4 py-2">10 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Вкопаване на комуникационен кабел в бетон на
                                                             л.м.
                                                             (с изключение на панелни блокове, след оглед)
            </td>
            <td className="border border-gray-400 px-4 py-2">15 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Свързване на климатик към електропанел</td>
            <td className="border border-gray-400 px-4 py-2">80 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Изработване на отклонителна платка за
                                                             електропанел
            </td>
            <td className="border border-gray-400 px-4 py-2">40 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Пускане на климатик в експлоатация с тест на
                                                             работата
            </td>
            <td className="border border-gray-400 px-4 py-2">50 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Обучение на клиент за работа с климатика</td>
            <td className="border border-gray-400 px-4 py-2">30 лв.</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Монтаж и демонтаж на съществуващ климатик</td>
            <td className="border border-gray-400 px-4 py-2">По договаряне</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div>
        <p>
          <strong>Уточнения:</strong> Цените за монтаж на мултисплит система са без включен тръбен път.
                                      Дължината на тръбното трасе между външното тяло и вътрешните тела се заплаща допълнително за всеки
                                      линеен метър.
        </p>
        <br/>
        <p>
          <strong>Клиентски материали и аксесоари не се монтират,</strong> но при настояване от клиента,
                                                                           услугите се ценообразуват по стойността на нашите материали и услуги.
        </p>
        <br/>
        <p>
          <strong>ВАЖНО:</strong> Ако е необходим монтаж с използване на вишка, цената за наемането на
                                  автовишката се поема изцяло от клиента и се определя съгласно актуалния ценоразпис на фирмата,
                                  предоставяща тази услуга.
        </p>
        <br/>
        <p>
          <strong>При инсталацията на климатична сплит система,</strong> като всеки монтаж, се изисква
                                                                         пробиване и запрашаване. В случай на помещения, в които вече се извършват ремонтни работи, това не
                                                                         представлява проблем. Въпреки това, в завършени жилища това може да причини дискомфорт на
                                                                         собствениците. <strong>Нашата препоръка е да подготвите стаята за инсталацията,</strong> като
                                                                         покриете пода и мебелите с найлоново покритие за строителни работи и премахнете всички предмети,
                                                                         които могат да пречат на монтирането на климатика – етажерки, високи шкафове, рафтове. Осигурете
                                                                         достъп до стената, на която ще бъде поставена вътрешната част на климатика, за да бъде извършена
                                                                         успешната инсталация. Винаги след инсталацията почистваме пода с прахосмукачка, но финият прах може
                                                                         да бъде предизвикателство за почистването на мебелите.
        </p>
      </div>
    </div>
  );
};

export default InstallationInfo;
