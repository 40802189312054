import { Link } from "react-router-dom";
import Logo from "../../../assets/logo.png";
import HeaderSearch from "./HeaderSearch";
import HeaderContact from "./HeaderContact";
import HeaderCart from "./HeaderCart";

export const Header = () => {
  return (
    <div className="container mx-auto">
      <div className="flex justify-between">
        <div className="my-auto w-full">
          <HeaderSearch/>
        </div>
        <div className="my-auto w-full justify-center flex">
          <Link to="/" className="block"><img src={Logo} className="w-56" alt=""/></Link>
        </div>
        <div className="my-auto w-full">
          <div className="grid grid-cols-2 gap-2">
            <HeaderContact/>
            <HeaderCart/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header;
