import { Link } from "react-router-dom";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const HeaderContact = () => {
  return (
    <div className="hidden xl:flex cursor-pointer">
      <Link to={"tel:+359893363628"}>
        <div className="flex flex-row items-center hover:opacity-70 transition duration-500 py-2 px-5 bg-gray-100 shadow-lg rounded-lg">
          <div className="text-3xl mr-4 text-[#2fa7e0]">
            <FontAwesomeIcon icon={faHeadset} />
          </div>
          <div className="flex flex-col">
            <span className={"text-xs text-gray-500 hidden xl:block "}>
              За запитвания
            </span>
            <span className={"text-lg font-bold text-black xl:block hidden whitespace-nowrap"}>
              0893 36 36 28
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default HeaderContact;
