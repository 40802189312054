import {categories} from "../../../data/categories";
import NavbarMegaItem from "./NavbarMegaItem";
import {NavbarDropdown} from "./NavbarDropdown";
import NavbarItem from "./NavbarItem";

export const Navbar = () => {
  return (
    <div className="hidden md:flex my-10 border-2 py-2 z-50">
      <div className="relative w-screen flex justify-center items-center text-gray-700 drop-shadow-md">
        {categories.map((e, index) => {
          let result;
          switch (e.type) {
            case 'MEGA_ITEM':
              result = <NavbarMegaItem key={index} label={e.label} categories={e.children}/>
              break;
            case 'DROPDOWN':
              result = <NavbarDropdown key={index} label={e.label} categories={e.children}/>
              break;
            default:
              result = <NavbarItem key={index} label={e.label}/>;
              break;
          }
          return result;
        })}
      </div>
    </div>
  )
}

export default Navbar;
