import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/free-solid-svg-icons';

interface CardInputProps {
  name?: string;
  id: string;
  label: string;
  placeholder: string;
  icon: IconDefinition;
  value: string;
  onChange: (data: any) => void;
  invalid?: boolean;
  invalidMessage?: string;
}

export const InputLabelIcon: React.FC<CardInputProps> = (
  {
    id,
    label,
    placeholder,
    icon,
    value,
    onChange,
    name,
    invalid,
    invalidMessage
  }) => {
  return (
    <div className="flex-grow">
      <label htmlFor={id} className="mt-4 w-full mb-2 block text-sm font-medium">
        {label}
      </label>
      <div className="relative">
        <input
          name={name}
          type="text"
          value={value}
          onChange={(e) => onChange(e)}
          id={id}
          className={`${invalid ? 'border-red-400' : 'border-gray-200'} w-full rounded-md border px-2 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500`}
          placeholder={placeholder}
        />
        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
          <FontAwesomeIcon icon={icon} className="h-4 w-4 text-gray-400"/>
        </div>
      </div>
      {
        invalidMessage &&
        <span className="text-sm text-red-600 ml-1">{invalidMessage}</span>
      }
    </div>
  );
};
