import {AddCartDetailType} from "../types/AddCartDetailType";
import {ENDPOINTS} from "../../../data/endpoints";
import api from "../../../lib/api";
import {CartShortInfoType} from "../types/CartShortInfoType";
import {CartDetailType} from "../types/CartDetailType";
import {CheckoutType} from "../types/CheckoutType";
import {CheckoutResponseType} from "../types/CheckoutResponseType";
import {FastCheckoutResponseType} from "../types/FastCheckoutResponseType";
import {FastCheckoutType} from "../types/FastCheckoutType";

export const addDetail = async (data: AddCartDetailType) => {
  return await api.post(ENDPOINTS.ADD_CART_DETAIL, data);
}

export const shortInfo = async () => {
  let vid = localStorage.getItem("vid") || '0';
  return await api.get<CartShortInfoType>(ENDPOINTS.GET_CART_SHORT_INFO + vid);
}

export const cartDetails = async () => {
  let vid = localStorage.getItem("vid") || '0';
  return await api.get<CartDetailType[]>(ENDPOINTS.GET_CART_DETAILS + vid);
}

export const increaseQty = async (detailId: string) => {
  const data = {
    detailId: detailId
  }
  return await api.post(ENDPOINTS.INCREASE_QTY, data);
}

export const decreaseQty = async (detailId: string) => {
  const data = {
    detailId: detailId
  }
  return await api.post(ENDPOINTS.DECREASE_QTY, data);
}

export const deleteDetail = async (detailId: string) => {
  const data = {
    detailId: detailId
  }
  return await api.post(ENDPOINTS.DELETE_DETAIL, data);
}

export const checkout = async (data: CheckoutType) => {
  return await api.post<CheckoutResponseType, CheckoutType>(ENDPOINTS.CHECKOUT, data);
}

export const fastCheckout = async (data: FastCheckoutType) => {
  return await api.post<FastCheckoutResponseType, FastCheckoutType>(ENDPOINTS.FAST_CHECKOUT, data);
}

const CartService = {
  addDetail,
  shortInfo,
  cartDetails,
  increaseQty,
  decreaseQty,
  deleteDetail,
  checkout,
  fastCheckout
}

export default CartService;
