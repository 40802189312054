import {useCustomMutation} from "../../../lib/query";
import cartService from "../service/cartService";
import {useCartStore} from "../store/cartStore";
import {useQueryClient} from "react-query";
import {QUERY_KEYS} from "../../../data/query-keys";

export const useDecreaseQty = (detailId: string) => {
  const queryClient = useQueryClient();
  const refetchCartInfo = useCartStore(state => state.refetchCartInfo);

  const onSuccess = () => {
    queryClient.refetchQueries(QUERY_KEYS.GET_CART_DETAILS)
    refetchCartInfo()
  }
  return useCustomMutation(() => cartService.decreaseQty(detailId), onSuccess);
}