import {DetailedProductType} from "../../feature/product/types/DetailedProductType";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import ProductPageDescription from "./ProductPageDescription";
import ProductPageDetails from "./ProductPageDetails";

interface Props {
  product: DetailedProductType
}

export const ProductPageAdvancedDetails: React.FC<Props> = ({product}) => {

  const [activeTab, setActiveTab] = useState('description');

  const handleTabClick = (tab: string, event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setActiveTab(tab);
  };

  return (
    <div className="lg:col-span-5">
      <div className="border-b border-gray-300">
        <nav className="flex gap-8">
          <Link
            title=""
            className={`py-4 text-sm font-medium ${
              activeTab === 'description' ? ' border-b-2 border-gray-900 text-gray-900' : 'text-gray-800'
            } hover:border-gray-400 hover:text-gray-800`}
            onClick={(event) => handleTabClick('description', event)} to="#">
            Описание
          </Link>
          <Link
            title=""
            className={` py-4 text-sm font-medium ${
              activeTab === 'characteristics' ? ' border-b-2 border-gray-900 text-gray-900' : 'text-gray-800'
            } hover:border-gray-400 hover:text-gray-800`}
            onClick={(event) => handleTabClick('characteristics', event)} to="#">
            Характеристики
          </Link>
        </nav>
      </div>

      <div className="mt-8 flow-root sm:mt-12">
        {activeTab === 'description' && (
          <ProductPageDescription product={product}/>
        )}
        {activeTab === 'characteristics' && (
          <ProductPageDetails product={product}/>
        )}
      </div>
    </div>
  )
}

export default ProductPageAdvancedDetails;