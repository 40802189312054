import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import {Sidebar} from "primereact/sidebar";
import {useEffect} from "react";
import currencyService from "../../../services/currencyService";
import {useCartStore} from "../../../feature/cart/store/cartStore";
import CartDetails from "../cart/CartDetails";

export const HeaderCart = () => {

  const cartShortInfo = useCartStore(state => state.cartShortInfo);
  const refetchCart = useCartStore(state => state.refetchCartInfo);
  const isCartOpen = useCartStore(state => state.cartOpen)
  const setCartOpen = useCartStore(state => state.setCartOpen);

  useEffect(() => {
    refetchCart();
  }, [refetchCart])

  return (
    <>
      <div className="hidden lg:flex flex-row items-center transition duration-500 py-2 px-5 hover:bg-gray-100 hover:shadow-lg rounded-lg cursor-pointer"
           onClick={() => setCartOpen(true)}>
        <div className="text-3xl mr-4 text-[#2fa7e0]">
          <FontAwesomeIcon icon={faShoppingCart}/>
        </div>
        <div className="flex flex-col">
          <>
            <span className={"text-xs text-gray-500"}>
              {cartShortInfo.products}
              {cartShortInfo.products === 1 ? ' продукт' : ' продукта'}
            </span>
            <span className={"text-lg font-bold text-black"}>{currencyService.format(cartShortInfo.totalPrice)}</span>
          </>
        </div>
      </div>


      <Sidebar visible={isCartOpen}
               position={"right"}
               onHide={() => setCartOpen(false)}
               pt={{
                 root: {
                   style: {
                     width: "28rem",
                   }
                 }
               }}>
        <div className="text-center my-2 text-4xl font-medium text-[#2fa7e0]">Количка</div>
        <CartDetails/>
      </Sidebar>
    </>
  )
}

export default HeaderCart;
