import CategoryPageFilters from "./category-page/CategoryPageFilters";
import {useParams} from "react-router-dom";
import CategoryProducts from "../feature/product/components/CategoryProducts";

export const CategoryPage = () => {

  const {slug} = useParams();

  return (
    <div className="grid grid-cols-12 gap-x-3">
      <div className="col-span-12 lg:col-span-3 p-5 bg-gray-100 mt-6 md:mt-0">
        <div className="text-3xl font-semibold tracking-wide">Категории</div>
        <div className="mt-3 border border-gray-200">
          <CategoryPageFilters/>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-9 p-5 md:p-0">
        {slug &&
          <CategoryProducts slug={slug}/>
        }
        {!slug &&
          <div className="flex justify-center text-gray-600">
            Няма намерени резултати
          </div>
        }
      </div>
    </div>
  )
}

export default CategoryPage