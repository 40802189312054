import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import desktopImage1 from '../../../assets/sliders/banner-mitsubishi.png';

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '500px'
};

const slideImages = [
  {
    desktop: desktopImage1,
    caption: 'Slide 1'
  },
  {
    desktop: desktopImage1,
    caption: 'Slide 2'
  }
];

const Slider = () => {
  return (
    <>
      <div className="slide-container py-5">
        <Slide>
          {slideImages.map((slideImage, index) => (
            <div key={index}>
              <div
                style={{
                  ...divStyle,
                  backgroundImage: `url(${slideImage.desktop})`
                }}
              >
                {/*<span style={spanStyle}>{slideImage.caption}</span>*/}
              </div>
            </div>
          ))}
        </Slide>
      </div>
    </>
  );
};

export default Slider;
