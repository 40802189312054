import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import {Link, useNavigate} from "react-router-dom";

export interface MenuLinkTypes {
  title: string;
  sublinks?: MenuLinkTypes[];
  href?: string;
  slug?: string;
}

interface ExpandableItemsProps {
  title: string;
  href?: string;
  links: MenuLinkTypes[];
  marginLeft?: number; // New prop to control the left margin
  currentPageParam: string; // New prop to get the current page parameter
  slug: string;
  autoExpand?: boolean;
  onLinkClick?: () => void;
}

export const ExpandableItems: React.FC<ExpandableItemsProps> = (
  {
    title,
    slug,
    links,
    marginLeft = 0,
    href,
    currentPageParam,
    autoExpand = true,
      onLinkClick,
  }) => {

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleTextClick = () => {
    if (!isOpen) {
      if (href) {
        navigate(href);
      }
      // window.location.href = href;
    } else {
      toggleMenu();
    }
  };

  const handleIconClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    toggleMenu();
  };

  useEffect(() => {
    let isCategoryExpanded = false;

    if (autoExpand) {

      if (currentPageParam === slug) {
        isCategoryExpanded = true;
      }

      links.forEach(e => {
        if (currentPageParam === e.slug) {
          isCategoryExpanded = true;
        }
      })

      links.forEach(e => {
        e.sublinks?.forEach(e => {
          if (currentPageParam === e.slug) {
            isCategoryExpanded = true;
          }
        })
      })
    }

    setIsOpen(isCategoryExpanded);
  }, [currentPageParam, slug, autoExpand, links]);

  return (
    <div>
      <button
        className="flex items-center uppercase justify-between w-full text-left text-sm font-semibold text-gray-700 hover:bg-gray-100 tracking-wide"
        onClick={handleTextClick}
      >
        <span className="p-3">{title}</span>
        {(!isOpen && links.length > 0) &&
          <span onClick={handleIconClick} className="p-3">
            <FontAwesomeIcon
              icon={faAngleDown}
              className={`transition-transform`}
            />
          </span>
        }
        {(isOpen && links.length > 0) &&
          <span onClick={handleIconClick} className="p-3">
            <FontAwesomeIcon
              icon={faAngleUp}
              className={`transition-transform`}
            />
          </span>
        }
      </button>
      {isOpen && (
        <div style={{marginLeft: `${marginLeft}px`}}>
          {links.map((link) => {
            if (link.sublinks) {
              return (
                <div className="ml-5" key={link.title}>
                  <ExpandableItems
                    slug={link.slug!}
                    title={link.title}
                    href={link.href}
                    links={link.sublinks}
                    marginLeft={marginLeft + 8}
                    currentPageParam={currentPageParam}
                    onLinkClick={onLinkClick}
                  />
                </div>
              );
            }
            return (
              <>
                {link.href &&
                  <Link key={link.title}
                        to={link.href}
                        className="block pl-8 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={onLinkClick}
                  >
                    {link.title}
                  </Link>
                }
                {!link.href &&
                  <div key={link.title} className="block pl-8 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    {link.title}
                  </div>
                }
              </>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default ExpandableItems;
