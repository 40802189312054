import {faBars, faPhone, faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {Sidebar} from "primereact/sidebar";
import {sidebarData} from "../../../data/sidebar-data";
import ExpandableItems from "../../../components/ExpandableItems";
import {Link, useParams} from "react-router-dom";
import {useCartStore} from "../../../feature/cart/store/cartStore";
import CartDetails from "../cart/CartDetails";

export const NavbarMobile = () => {

  const [show, setShow] = useState<boolean>(false);
  const {slug} = useParams();
  const refetchCart = useCartStore(state => state.refetchCartInfo);
  const isCartOpen = useCartStore(state => state.cartOpen)
  const setCartOpen = useCartStore(state => state.setCartOpen);

  useEffect(() => {
    refetchCart();
  }, [refetchCart])

  return (
    <>
      <div className="flex flex-row md:hidden">
        <div className="absolute top-7 left-5 cursor-pointer" onClick={() => setShow(true)}>
          <FontAwesomeIcon icon={faBars} size={'2xl'} className="text-sky-600 hover:text-sky-900 transition"/>
        </div>

        <div className="absolute top-7 right-1 cursor-pointer">
          <div className="w-1/3 flex flex-row text-3xl text-sky-600 justify-end gap-4 ">
            <div className="hover:text-gray-900 cursor-pointer">
              <Link to={"tel:+359893363628"}>
                <FontAwesomeIcon icon={faPhone}/>
              </Link>
            </div>
            <div className="hover:text-gray-900 cursor-pointer" onClick={() => setCartOpen(true)}>
              <button type="button"><FontAwesomeIcon icon={faShoppingCart}/></button>
            </div>
          </div>
        </div>

        <div className="card flex justify-content-center">
          <Sidebar
            visible={show}
            className="w-10/12"
            onHide={() => setShow(false)}
          >
            <div className="bg-white">
              {sidebarData.map((link) => (
                <ExpandableItems
                  autoExpand={false}
                  key={link.title}
                  href={link.href}
                  title={link.title}
                  links={link.sublinks || []}
                  slug={link.slug!}
                  currentPageParam={slug!}
                  onLinkClick={() => setShow(false)}
                />
              ))}
            </div>
          </Sidebar>
        </div>

      </div>
      <Sidebar
        visible={isCartOpen}
        position={"right"}
        onHide={() => setCartOpen(false)}
        pt={{
          root: {
            style: {
              width: "28rem",
            }
          }
        }}
      >
        <div className="text-center my-2 text-4xl font-medium text-[#2fa7e0]">Количка</div>
        <CartDetails/>
      </Sidebar>
    </>
  )
}

export default NavbarMobile;
