import {useCartStore} from "../../../feature/cart/store/cartStore";
import currencyService from "../../../services/currencyService";
import {useGetCartDetails} from "../../../feature/cart/hooks/useGetCartDetails";
import CartDetailRow from "./CartDetailRow";
import {useNavigate} from "react-router-dom";
import Spinner from "../../../components/Spinner";

export const CartDetails = () => {

  const totalPrice = useCartStore(state => state.cartShortInfo.totalPrice);
  const setCartOpen = useCartStore(state => state.setCartOpen);

  const {data, isLoading, isError} = useGetCartDetails();

  const navigate = useNavigate();

  if (isLoading || isError || !data) {
    return (
      <Spinner/>
    )
  }

  if (!data || data.length === 0) {
    return (
      <div className="font-medium text-xl tracking-wider text-center">
        Вашата количка е празна
      </div>
    )
  }

  return (
    <div>
      <div className="overflow-y-auto max-h-[400px] md:max-h-[600px]">
        {data!.map((product) => (
          <CartDetailRow key={product.productSlug} detail={product}/>
        ))}
      </div>
      {data && (
        <>
          <div className="mr-5 pt-10 md:pt-2 flex flex-row gap-2 items-center justify-end">
            Обща цена: <span className="font-bold text-lg">{currencyService.format(totalPrice)}</span>
          </div>
        </>
      )}

      <div className="mt-5 lg:mt-7 justify-center flex">
        <div onClick={() => {
          setCartOpen(false)
          navigate("/checkout")
        }}
             className="bg-[#0ea5e9] hover:bg-[#69c7f1] transition w-fit p-3 rounded text-white font-semibold tracking-wider cursor-pointer">
          Продължи към плащането
        </div>
      </div>
    </div>
  )
}

export default CartDetails;