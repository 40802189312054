import SingleProduct from "../../../layouts/components/product/SingleProduct";
import {useGetOurOffers} from "../hooks/useGetOurOffers";
import Spinner from "../../../components/Spinner";

export const OurOffers = () => {

  const {data, isLoading, isError} = useGetOurOffers();

  if (isLoading || isError || !data) {
    return <Spinner/>
  }

  const eightProducts = data.slice(0, 8);

  return (
    <div>
      <div className="text-3xl font-semibold p-2 lg:p-0 lg:pb-4">
        Нашите предложения
      </div>
      <div className="relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 lg:p-0 gap-4">
        {eightProducts.map(e => <SingleProduct key={e.slug} product={e}/>)}
      </div>
    </div>
  )
}

export default OurOffers;
