import OurOffers from "../feature/product/components/OurOffers";
import MobileSlider from "../layouts/components/sliders/MobileSlider";
import Slider from "../layouts/components/sliders/Slider";
import CategoriesGrid from "./CategoriesGrid";

export const Home = () => {
  return (
    <div>
      <div className="md:block hidden">
        <Slider/>
      </div>
      <div className="md:hidden ">
        <MobileSlider/>
      </div>
      <div className="pb-5">
        <CategoriesGrid/>
      </div>
      <OurOffers/>
    </div>
  )
}

export default Home;
