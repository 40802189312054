import React, {useState} from "react";
import {DetailedProductType} from "../../feature/product/types/DetailedProductType";

interface Props {
  product: DetailedProductType;
}

export const ProductPageImages: React.FC<Props> = ({product}) => {

  const [selectedImage, setSelectedImage] = useState(product.mainImageUrl);

  return (
    <div className="lg:col-span-3 lg:row-end-1">
      <div className="lg:flex lg:items-start">
        <div className="lg:order-2 lg:ml-5">
          <div className="max-w-xl overflow-hidden rounded-lg relative">
            <img className="h-full w-full max-w-full object-cover" src={selectedImage} alt={product.name}/>
          </div>
        </div>
        <div className="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
          <div className="flex flex-row items-start lg:flex-col gap-1">
            {product.images.map((image, index) => (
              <button
                key={index}
                type="button"
                className={`flex-0 aspect-square mb-3 h-20 overflow-hidden md:rounded-lg md:border-2 ${
                  selectedImage === image.url ? 'border-sky-500' : 'border-gray-900'
                } text-center`}
                onClick={() => setSelectedImage(image.url)}
              >
                <img className="h-full w-full object-cover" src={image.url} alt={product.name}/>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
};

export default ProductPageImages;
