import {AutoComplete, AutoCompleteChangeEvent, AutoCompleteCompleteEvent} from "primereact/autocomplete";
import {useEffect, useState} from "react";
import {useGetSearchItems} from "../../../feature/product/hooks/useGetSearchItems";
import {ShortProductType} from "../../../feature/product/types/ShortProductType";
import {useNavigate} from "react-router-dom";

export const HeaderSearch = () => {

  const navigate = useNavigate();

  const [items, setItems] = useState<ShortProductType[]>([]);
  const [selectedItem, setSelectedItem] = useState<ShortProductType>();
  const [filteredItems, setFilteredItems] = useState<ShortProductType[]>([]);

  const {data, isLoading, isError} = useGetSearchItems();

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setItems(data!)
    }
  }, [data, isLoading, isError])

  const search = (event: AutoCompleteCompleteEvent) => {
    // Timeout to emulate a network connection
    setTimeout(() => {
      let _filteredItems;

      if (!event.query.trim().length) {
        _filteredItems = [...items];
      } else {
        _filteredItems = items?.filter((item) => {
          return item.name.toLowerCase().includes(event.query.toLowerCase());
        });
      }

      setFilteredItems(_filteredItems);
    }, 250);
  }

  const itemTemplate = (item: ShortProductType) => {
    return (
      <div className="flex items-center  p-4 rounded-lg" onClick={() => {
        navigate("/product/" + item.slug);
      }}>
        <img src={item.mainImageUrl} alt={item.name} className="w-12 h-12 rounded-full mr-4"/>
        <div className="font-bold">{item.name}</div>
      </div>
    );
  };

  return (
    <div className="hidden xl:flex w-full">
      <AutoComplete
        dropdown
        field={"name"}
        dropdownIcon="pi pi-search"
        className="w-full w-fit shadow-xl group rounded"
        inputClassName="bg-gray-100 border-none shadow-none group-hover:bg-gray-200"
        placeholder="Търсене по марка и модел"
        value={selectedItem}
        suggestions={filteredItems}
        completeMethod={search}
        onChange={(e: AutoCompleteChangeEvent) => setSelectedItem(e.value)}
        itemTemplate={itemTemplate}
        pt={{
          dropdownButton: {
            root: {
              className: "bg-gray-100 border-none text-[#2fa7e0] text-xl group-hover:bg-gray-200"
            }
          }
        }}
      />
    </div>
  )
}

export default HeaderSearch