import React from "react";

interface PricingCardProps {
  title: string;
  subtitle: string;
  price: string;
  features: string[];
}

const PricingCard: React.FC<PricingCardProps> = ({title, subtitle, price, features}) => {

  return (
    <>
      <div className="w-full md:w-1/3 mx-4">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-2xl font-bold mb-4">{title}</h2>
          <h3 className="text-xl font-bold mb-4">{subtitle}</h3>
          <p className="text-3xl font-bold text-gray-800 mb-4">{price}</p>
          <ul className="text-gray-600 mb-6">
            {features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
        </div>
      </div>

    </>
  );
};

export default PricingCard;
