import {useCustomQuery} from "../../../lib/query";
import {QUERY_KEYS} from "../../../data/query-keys";
import productService from "../service/productService";

export const useGetProductsByCategory = (slug: string) => {
  const {data, isLoading, isError} = useCustomQuery({
    key: [QUERY_KEYS.GET_PRODUCTS_BY_CATEGORY_SLUG, slug],
    query: () => productService.getProductByCategorySlug(slug),
  });

  if (isLoading || isError || !data) {
    return {isLoading, isError};
  }

  return {data, isLoading, isError};
}