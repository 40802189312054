import React from "react";
import {Link} from "react-router-dom";

interface Props {
  label: string;
  categories: any[];
}

export const NavbarMegaItem: React.FC<Props> = ({label, categories}) => {
  return (
    <div className="group">
      <button className="px-5 py-4 group-hover:bg-[#2fa7e0] group-hover:text-white text-md tracking-wide uppercase font-semibold rounded">
        {label}
      </button>

      <div className="hidden group-hover:flex flex-col absolute left-0 p-10 w-full text-black duration-300 shadow-xl bg-white">
        <div className="pb-5">
          <h2 className="text-3xl font-semibold text-[#2fa7e0] underline">{label}</h2>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-5">

          {categories.map((e, index) => {
            return (
              <div key={index} className="flex flex-col">
                <h3 className="mb-4 text-xl text-gray-700 font-medium">{e.label}</h3>
                {e.children &&
                  e.children.map((a: any, innerIndex: number) => {
                    return (
                      <Link key={innerIndex} to={`/category/${a.slug}`} className="hover:underline hover:text-[#2fa7e0]">
                        {a.label}
                      </Link>
                    )
                  })
                }
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default NavbarMegaItem;