import {AddCartDetailType} from "../types/AddCartDetailType";
import {useCustomMutation} from "../../../lib/query";
import cartService from "../service/cartService";
import {useCartStore} from "../store/cartStore";
import {useQueryClient} from "react-query";
import {QUERY_KEYS} from "../../../data/query-keys";
import {toastService} from "../../../services/toastService";

export const useAddCartDetail = (data: AddCartDetailType) => {
  const queryClient = useQueryClient();
  const refetchCartInfo = useCartStore(state => state.refetchCartInfo);

  const onSuccess = () => {
    toastService.info("Продукта е добавен в количката")
    refetchCartInfo();
    queryClient.refetchQueries(QUERY_KEYS.GET_CART_DETAILS);
  }
  return useCustomMutation(() => cartService.addDetail(data), onSuccess);
}
