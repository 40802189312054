import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import desktopImage1 from '../../../assets/sliders/vertical-mitsubishi.png';

interface SlideImage {
  desktop: string;
  caption: string;
}

const slideImages: SlideImage[] = [
  {
    desktop: desktopImage1,
    caption: 'Slide 1'
  },
  {
    desktop: desktopImage1,
    caption: 'Slide 2'
  }
];

const MobileSlider: React.FC = () => {
  const imgStyle: React.CSSProperties = {
    objectFit: 'contain',
    width: '100%',
  };

  return (
    <div className="slide-container py-5">
      <Slide>
        {slideImages.map((slideImage, index) => (
          <div key={index}>
            <div>
              <img
                src={slideImage.desktop}
                alt={slideImage.caption}
                style={imgStyle}
              />
            </div>
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default MobileSlider;
