import React from "react";
import {DetailedProductType} from "../../feature/product/types/DetailedProductType";

interface Props {
  product: DetailedProductType
}

export const ProductPageDescription: React.FC<Props> = ({product}) => {
  return (
    <div dangerouslySetInnerHTML={{__html: product.description}}/>
  )
}

export default ProductPageDescription;