import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import Logo from '../../../assets/logo.png';

const Footer = () => {
  return (
    <footer className="text-gray-600 body-font">
      <div className="container py-8 mx-auto flex items-center sm:flex-row flex-col">
        <Link to="/" className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
          <Link to="/"><img src={Logo} className="w-36" alt=""/></Link>
        </Link>
        <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
          © 2023 Yelsclima
        </p>
        <div className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
          <a href="https://www.facebook.com/yelsclimacom/" target="_blank" rel="noreferrer" className="text-gray-500">
            <FontAwesomeIcon icon={faFacebook}/>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
