import {CartDetailType} from "../../../feature/cart/types/CartDetailType";
import React from "react";
import currencyService from "../../../services/currencyService";
import {useDeleteDetail} from "../../../feature/cart/hooks/useDeleteDetail";
import {useIncreaseQty} from "../../../feature/cart/hooks/useIncreaseQty";
import {useDecreaseQty} from "../../../feature/cart/hooks/useDecreaseQty";

interface Props {
  detail: CartDetailType;
}

export const CartDetailRow: React.FC<Props> = ({detail}) => {

  const removeDetail = useDeleteDetail(detail.id);
  const increaseQty = useIncreaseQty(detail.id);
  const decreaseQty = useDecreaseQty(detail.id);

  return (
    <div className="mt-2 md:mt-0">
      <li className="flex items-center md:gap-4 md:p-4">
        <div className="mr-2 md:mr-0">
          <img src={detail.productMainImageUrl} alt="" className="h-16 w-16 rounded object-cover"/>
        </div>
        <div>
          <h3 className="text-sm text-gray-900">{detail.productName}</h3>
          <dl className="mt-0.5 space-y-px text-[10px] text-gray-600">
            <dt className="block">Цена:
              <span className="ml-1 font-semibold">
                {currencyService.format(detail.basePrice)}
              </span>
            </dt>
            <dt className="block">Монтаж:
              <span className="ml-1 font-semibold">
                {detail.withInstallation
                  ? currencyService.format(detail.installationPrice)
                  : 'Без монтаж'}
              </span>
            </dt>
            {detail.additionalWarrantyPeriod !== 0 &&
              <dt className="block">
                Доп. гаранция:
                <span className="ml-1 font-semibold">
                  {currencyService.format((detail.additionalWarrantyPrice * detail.additionalWarrantyPeriod))}
                </span>
                <span className="ml-1 font-semibold">({detail.additionalWarrantyPeriod}г.)</span>
              </dt>
            }
            <div className="text-right tracking-wide">
              <span className="text-xs text-base text-black mr-1">Общо:</span>
              <span className="text-sm font-semibold">{currencyService.format(detail.totalPrice)}</span>
            </div>
          </dl>
        </div>

        <div className="flex flex-1 items-center justify-end gap-2">
          <form>
            <label htmlFor="Line1Qty" className="sr-only">Количество</label>
            <button type="button"
                    onClick={() => increaseQty.mutate()}
                    className="font-bold ml-5">
              +
            </button>
            <input type="number"
                   min="1"
                   value={detail.quantity}
                   readOnly={true}
                   id="Line1Qty"
                   className="h-8 w-12 font-semibold rounded border-gray-200 bg-gray-50 p-0 text-center text-xs text-gray-600 [-moz-appearance:_textfield]
                                  focus:outline-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0
                                  [&::-webkit-outer-spin-button]:appearance-none"/>
            <button type="button"
                    onClick={() => decreaseQty.mutate()}
                    className="font-bold ml-5">-
            </button>
          </form>


          <button
            className="text-gray-600 transition hover:text-red-600"
            onClick={() => removeDetail.mutate()}
          >

            <span className="sr-only">Премахни</span>

            <svg xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24"
                 strokeWidth="1.5"
                 stroke="currentColor"
                 className="h-4 w-4">

              <path strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
            </svg>
          </button>
        </div>
      </li>
    </div>
  )
}

export default CartDetailRow;