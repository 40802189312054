import React from 'react';

import Stenen from "../assets/category-grid/Stenen-klimatik.png";
import Podov from "../assets/category-grid/Podov-klimatik.png";
import Tavanen from "../assets/category-grid/Tavanen-klimatik.png";
import Termopompi from "../assets/category-grid/termopompa.png";
import Survuren from "../assets/category-grid/Survuren-klimatik.png";
import Multisplit
  from "../assets/category-grid/multisplit-sistema-mitsubishi-heavy-industries-scm45-zs-w-vynshno-tjalo-1.png";
import {Link} from "react-router-dom";

const CategoriesGrid: React.FC = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
      <div
        className="bg-gradient-to-tl from-sky-300 to-sky-500 hover:from-sky-400 hover:to-sky-500 md:h-full h-96 flex shadow-lg justify-center items-center">
        <div>
          <h4 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-white md:text-2xl lg:text-4xl">
            <span className="underline underline-offset-4 decoration-8 decoration-sky-600">Климатици</span>
          </h4>
        </div>
      </div>
      <div className="box md:flex md:flex-col space-y-4 md:space-y-0 md:gap-3">
        <div
          className="bg-gradient-to-tl from-gray-100 to-gray-200 hover:to-gray-300 shadow hover:transition hover:duration-500 transition duration-500 hover:text-sky-500 hover:scale-105 h-1/2 h-96 md:h-96 flex flex-col justify-center items-center">
          <div className="flex flex-col items-center">
            <Link to="/category/битова-климатизация-стенен-тип">
              <div>
                <img src={Stenen} alt="" className="mx-auto h-52"/>
              </div>
              <div className="text-center font-bold uppercase md:mt-10 text-xl">
                Стенни климатици
              </div>
            </Link>
          </div>
        </div>
        <div
          className="bg-gradient-to-tl from-gray-100 to-gray-200 hover:to-gray-300 shadow hover:transition hover:duration-500 transition duration-500 hover:text-sky-500 hover:scale-105 h-1/2 h-96 md:h-96 flex flex-col justify-center items-center">
          <div className="flex flex-col items-center">
            <Link to="/category/битова-климатизация-мултисплит-системи">
              <div>
                <img src={Multisplit} alt="" className="mx-auto h-52"/>
              </div>
              <div className="text-center font-bold uppercase md:mt-10 text-xl">
                Мултисплит системи
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="box md:flex md:flex-col space-y-4 md:space-y-0 md:gap-3">
        <div
          className="bg-gradient-to-tl from-gray-100 to-gray-200 hover:to-gray-300 shadow hover:transition hover:duration-500 transition duration-500 hover:text-sky-500 hover:scale-105 h-1/2 h-96 md:h-96 flex flex-col justify-center items-center">
          <div className="flex flex-col items-center">
            <Link to="/category/битова-климатизация-подови-климатици">
              <div>
                <img src={Podov} alt="" className="mx-auto h-52"/>
              </div>
              <div className="text-center font-bold uppercase md:mt-10 text-xl">
                Подови климатици
              </div>
            </Link>
          </div>
        </div>
        <div
          className="bg-gradient-to-tl from-gray-100 to-gray-200 hover:to-gray-300 shadow hover:transition hover:duration-500 transition duration-500 hover:text-sky-500 hover:scale-105 h-1/2 h-96 md:h-96 flex flex-col justify-center items-center">
          <div className="flex flex-col items-center">
            <Link to="/category/термопомпени-системи">
              <div>
                <img src={Termopompi} alt="" className="mx-auto h-52"/>
              </div>
              <div className="text-center font-bold uppercase md:mt-10 text-xl">
                Термопомпи
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="box md:flex md:flex-col space-y-4 md:space-y-0 md:gap-3">
        <div
          className="bg-gradient-to-tl from-gray-100 to-gray-200 hover:to-gray-300 shadow hover:transition hover:duration-500 transition duration-500 hover:text-sky-500 hover:scale-105 h-1/2 h-96 md:h-96 flex flex-col justify-center items-center">
          <div className="flex flex-col items-center">
            <Link to="/category/сървърни-климатици">
              <div>
                <img src={Survuren} alt="" className="mx-auto h-52"/>
              </div>
              <div className="text-center font-bold uppercase md:mt-10 text-xl">
                Сървърни климатици
              </div>
            </Link>
          </div>
        </div>
        <div
          className="bg-gradient-to-tl from-gray-100 to-gray-200 hover:to-gray-300 shadow hover:transition hover:duration-500 transition duration-500 hover:text-sky-500 hover:scale-105 h-1/2 h-96 md:h-96 flex flex-col justify-center items-center">
          <div className="flex flex-col items-center">
            <Link to="/category/таванни-климатици">
              <div>
                <img src={Tavanen} alt="" className="mx-auto h-52"/>
              </div>
              <div className="text-center font-bold uppercase md:mt-10 text-xl">
                Таванни климатици
              </div>
            </Link>
          </div>
        </div>
      </div>


    </div>


  );
};

export default CategoriesGrid;
