import {useCustomQuery} from "../../../lib/query";
import {QUERY_KEYS} from "../../../data/query-keys";
import productService from "../service/productService";

export const useGetProductBySlug = (slug: string) => {
  const {data, isLoading, isError} = useCustomQuery({
    key: [QUERY_KEYS.GET_PRODUCT_BY_SLUG, slug],
    query: () => productService.getProductBySlug(slug),
  });

  if (isLoading || isError || !data) {
    return {isLoading, isError};
  }

  return {data, isLoading, isError};
}