export const QUERY_KEYS = {
  // Categories

  // Products
  GET_OUR_OFFERS: "GET_OUR_OFFERS",
  GET_PRODUCT_BY_SLUG: "GET_PRODUCT_BY_SLUG",
  GET_PRODUCTS_BY_CATEGORY_SLUG: "GET_PRODUCTS_BY_CATEGORY_SLUG",
  GET_SEARCH_ITEMS: "GET_SEARCH_ITEMS" ,

  // Cart
  GET_CART_SHORT_INFO: "GET_CART_SHORT_INFO",
  GET_CART_DETAILS: "GET_CART_DETAILS",
}
