export const ENDPOINTS = {
  // Categories

  // Product
  GET_OUR_OFFERS: "/eshop/product/featured-products",
  GET_PRODUCT_BY_SLUG: "/eshop/product/",
  GET_PRODUCTS_BY_CATEGORY_SLUG: "/eshop/product/category/",
  GET_SEARCH_ITEMS: "/eshop/product/search",

  // Cart
  ADD_CART_DETAIL: "/eshop/cart/add",
  GET_CART_SHORT_INFO: "/eshop/cart/info/",
  GET_CART_DETAILS: "/eshop/cart/details/",
  INCREASE_QTY: "/eshop/cart/det/inc",
  DECREASE_QTY: "/eshop/cart/det/dec",
  DELETE_DETAIL: "/eshop/cart/det/del",
  CHECKOUT: "/eshop/cart/checkout",
  FAST_CHECKOUT: "/eshop/cart/fast-checkout"
};
