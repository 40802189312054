import {useParams} from "react-router-dom";
import {useGetProductBySlug} from "../feature/product/hooks/useGetProductBySlug";
import Spinner from "../components/Spinner";
import ProductPageImages from "./product-page/ProductPageImages";
import currencyService from "../services/currencyService";
import ProductPageCartButton from "./product-page/ProductPageCartButton";
import ProductPageWarranty from "./product-page/ProductPageWarranty";
import React, {useState} from "react";
import ProductPageInstallation from "./product-page/ProductPageInstallation";
import ProductPageAdvancedDetails from "./product-page/ProductPageAdvancedDetails";
import { Helmet } from 'react-helmet';
import ProductPageTbiApplication from "./product-page/ProductPageTbiApplication";

export const ProductPage = () => {

    const {slug} = useParams<{ slug: string }>();

    const {data, isLoading, isError} = useGetProductBySlug(slug!);

    const [withInstallation, setWithInstallation] = useState<boolean>(false);
    const [additionalWarrantyPeriod, setAdditionalWarrantyPeriod] = useState<number>(0);

    if (isLoading || isError || !data) {
        return <Spinner/>
    }

    const totalPrice = () => {
        let totalPrice = data.hasPromo ? data.promoPrice : data.singlePrice;

        if (withInstallation) {
            totalPrice += data.installationPrice;
        }

        totalPrice += additionalWarrantyPeriod * data.additionalWarrantyPrice;

        return totalPrice;
    }

    const totalDefaultPrice = () => {
        let totalPrice = data.singlePrice;

        if (withInstallation) {
            totalPrice += data.installationPrice;
        }

        totalPrice += additionalWarrantyPeriod * data.additionalWarrantyPrice;

        return totalPrice;
    }

    return (
        <>
            <Helmet>
                <title>{data.name} - Yelsclima</title>
            </Helmet>
            <section className="py-12 sm:py-16">
                <div className="container mx-auto px-4">
                    <div
                        className="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
                        <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl md:hidden block">{data.name}</h1>
                        <ProductPageImages product={data}/>

                        <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
                            <h1 className="sm:text-2xl font-semibold text-gray-900 md:block hidden">{data.name}</h1>

                            <ProductPageWarranty
                                product={data}
                                value={additionalWarrantyPeriod}
                                onChange={setAdditionalWarrantyPeriod}
                            />

                            {data.installationPrice > 0 && (
                                <ProductPageInstallation
                                    product={data}
                                    value={withInstallation}
                                    onChange={setWithInstallation}
                                />
                            )}

                            <div
                                className="mt-10 flex flex-col items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">

                                {data.hasPromo &&
                                    <>
                                    <span
                                        className="text-3xl font-semibold ml-2 text-gray-600">{currencyService.format(totalPrice())}</span>
                                        <span
                                            className="text-xl font-semibold ml-2 text-gray-600 line-through">{currencyService.format(totalDefaultPrice())}</span>
                                    </>
                                }

                                {!data.hasPromo &&
                                    <span
                                        className="text-3xl font-semibold ml-2 text-gray-600">{currencyService.format(totalPrice())}</span>
                                }

                                <ProductPageCartButton product={data}
                                                       withInstallation={withInstallation}
                                                       additionalWarrantyPeriod={additionalWarrantyPeriod}/>
                            </div>

                            {/*<div className="pt-2">*/}
                            {/*  <ProductPageFastCheckout product={data} withInstallation={withInstallation}*/}
                            {/*                           additionalWarrantyPeriod={additionalWarrantyPeriod}/>*/}
                            {/*</div>*/}

            <div className="pt-2 grid grid-cols-1 md:grid-cols-2 gap-2">
              <ProductPageTbiApplication product={data}
                                         withInstallation={withInstallation}
                                         additionalWarrantyPeriod={additionalWarrantyPeriod}/>
            </div>
          </div>

                        <ProductPageAdvancedDetails product={data}/>

                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductPage;
