import React from "react";

interface PaymentMethod {
  id: string;
  label: string;
  description: string;
  onClick?: () => void;
  checked: boolean;
}

const MethodBox: React.FC<PaymentMethod> = ({id, label, description, onClick, checked}) => {
  return (
    <div className="relative" key={id} onClick={onClick}>
      <input className="peer hidden" id={`radio_${id}`} type="radio" name="radio" checked={checked}/>
      <span
        className="peer-checked:border-gray-700 absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white"
      />
      <label
        className="peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-4"
        htmlFor={`radio_${id}`}
      >
        <div className="ml-2">
          <span className="mt-2 font-semibold">{label}</span>
          <p className="text-slate-500 text-sm leading-6">{description}</p>
        </div>
      </label>
    </div>
  );
};

export default MethodBox;
