export const format = (price: number): string => {
  return new Intl.NumberFormat('bg-BG', {
    style: 'currency',
    currency: 'BGN',
    minimumFractionDigits: 2,
    useGrouping: true
  }).format(price);
}

export const removePercentage = (value: number, percentage: number): number => {
  const percentageToRemove = (percentage / 100) * value;
  return value - percentageToRemove;
}

export const CurrencyService = {
  format,
  removePercentage
};

export default CurrencyService;