import {useCustomMutation} from "../../../lib/query";
import cartService from "../service/cartService";
import {useCartStore} from "../store/cartStore";
import {useQueryClient} from "react-query";
import {QUERY_KEYS} from "../../../data/query-keys";

export const useDeleteDetail = (detailId: string) => {
  const refetchCartInfo = useCartStore(state => state.refetchCartInfo);
  const queryClient = useQueryClient();

  const onSuccess = () => {
    queryClient.refetchQueries(QUERY_KEYS.GET_CART_DETAILS)
    refetchCartInfo()
  }
  return useCustomMutation(() => cartService.deleteDetail(detailId), onSuccess);
}