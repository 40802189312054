import {ENDPOINTS} from "../../../data/endpoints";
import api from "../../../lib/api";
import {ShortProductType} from "../types/ShortProductType";
import {DetailedProductType} from "../types/DetailedProductType";

const getOurOffers = async () => {
  return await api.get<ShortProductType[]>(ENDPOINTS.GET_OUR_OFFERS);
}

const getProductBySlug = async (slug: string) => {
  return await api.get<DetailedProductType>(ENDPOINTS.GET_PRODUCT_BY_SLUG + slug);
}

const getProductByCategorySlug = async (categorySlug: string) => {
  return await api.get<ShortProductType[]>(ENDPOINTS.GET_PRODUCTS_BY_CATEGORY_SLUG + categorySlug);
}

const getSearchItems = async () => {
  return await api.get<ShortProductType[]>(ENDPOINTS.GET_SEARCH_ITEMS);
}

const ProductService = {
  getOurOffers,
  getProductBySlug,
  getProductByCategorySlug,
  getSearchItems
}

export default ProductService;