import * as Yup from 'yup';

const fastCheckoutSchema = Yup.object().shape({
  firstName: Yup.string().required("Полето е задължително"),
  lastName: Yup.string().required("Полето е задължително"),
  phoneNumber: Yup.string().required("Полето е задължително"),
  email: Yup.string()
            .email("Невалиден имейл адрес")
            .required("Полето е задължително"),
});

export default fastCheckoutSchema;