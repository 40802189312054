import React from 'react';

const PaymentMethods: React.FC = () => {
  return (
    <div className="p-4">

      <h3 className="md:text-4xl font-black tracking-wider text-center pb-5">Начини на плащане</h3>

      <div className="mb-8">
        <h3 className="text-2xl font-bold mb-2">Плащане онлайн с банкова карта</h3>
        <p className="text-lg mb-4">
          Може да направите плащане през нашия сайт със следните банкови карти:
          <span className="font-semibold">MasterCard, MasterCard Electronic, Maestro, Visa, Visa Electron и V PAY.</span>
        </p>
        <p className="text-lg">Транзакционната валута при плащане с карта е български лев (лв.)</p>
      </div>

      <div className="mb-8">
        <h3 className="text-2xl font-bold mb-2">Данни, които клиентът трябва да попълни при плащане с карта:</h3>
        <ul className="list-disc pl-8">
          <li className="text-lg mb-2">Данни за картата (номер, дата на валидност, 3-цифрен код за сигурност, име на картодържателя)</li>
          <li className="text-lg">Данни за автентикация: За идентифицирането ви като картодържател, платежният сървър за електронна търговия на обслужващата ни банка поддържа схемите за автентикация на международните картови организации.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h3 className="text-2xl font-bold mb-2">Плащане по банков път</h3>
        <p className="text-lg mb-4">
          Може да заплатите избраните продукти по банков път.
          Може да изпратим проформа фактура, по която може да осъществите банковия превод по имейл.
          Получаването на стоката се осъществява след отразяване на банковия превод в нашата сметка или при предоставяне на платежно нареждане от страна на клиента.
        </p>
        <p className="text-lg">При направена поръчка през сайта получавате Инструкции за банков превод, както следва:</p>
        <p className="text-lg">Получател: <span className="font-semibold">НСВ ЕООД</span></p>
        <p className="text-lg">Банка: <span className="font-semibold">iCARD AD</span></p>
        <p className="text-lg">IBAN: <span className="font-semibold">BG79INTF40012043625560</span></p>
        <p className="text-lg">BIC: <span className="font-semibold">INTFBGSF</span></p>
        <p className="text-lg">За основание напишете номера на поръчката.</p>
        <p className="text-lg">Поръчаната стока ще бъде изпратена след отразяване на парите по нашата сметка или изпращане на имейл с платежно нареждане на <span className="font-semibold">office@yelsclima.com</span></p>
      </div>

      <div>
        <h3 className="text-2xl font-bold mb-2">Покупка на изплащане – стоков кредит</h3>
        <p className="text-lg mb-4">Не е нужно да ходите до офис, за да вземете продукт на изплащане. Предлагаме ви бърз и удобен начин за кандидатстване напълно онлайн.</p>
        <ol className="list-decimal pl-8">
          <li className="text-lg mb-2">Изберете продукт от сайта.</li>
          <li className="text-lg mb-2">Натиснете бутон “Купи от TBI”, където може да разгледате различни схеми за разсрочено плащане, които предлагаме.</li>
          <li className="text-lg mb-2">След като сте се запознали с възможните варианти за разсрочено плащане, натиснете бутон “Заяви и купи сега” и следвайте стъпките, като попълвате задължителните полета.</li>
          <li className="text-lg">Наш служител ще се свърже с Вас в най-кратък срок, за да уточните подробностите по направената поръчка. След като вече сте одобрен, ще се свържем отново с Вас, за да уточним подробности относно доставката за съответния продукт.</li>
        </ol>
      </div>
    </div>
  );
};

export default PaymentMethods;
