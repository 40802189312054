import * as Yup from 'yup';

const checkoutSchema = Yup.object().shape({
  firstName: Yup.string().required("Полето е задължително"),
  secondName: Yup.string().optional(),
  lastName: Yup.string().required("Полето е задължително"),
  phoneNumber: Yup.string().required("Полето е задължително"),
  email: Yup.string()
            .email("Невалиден имейл адрес")
            .required("Полето е задължително"),
  city: Yup.string().required("Полето е задължително"),
  region: Yup.string().required("Полето е задължително"),
  postCode: Yup.string().required("Полето е задължително"),
  deliveryAddress: Yup.string().required("Полето е задължително")
});

export default checkoutSchema;