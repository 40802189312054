import Navbar from "./components/navbar/Navbar";
import Header from "./components/header/Header";
import {Outlet} from "react-router-dom";
import NavbarMobile from "./components/navbar-m/NavbarMobile";
import Footer from "./components/footer/Footer";

export const MainLayout = () => {

  return (
    <div className="my-10">
      <div className="z-50 relative items-center ">
        <Header/>
        <Navbar/>
        <NavbarMobile/>
      </div>

      <div className="relative container mx-auto">
        <Outlet/>
      </div>
      <Footer />
    </div>
  )
}

export default MainLayout;
