import {DetailedProductType} from "../../feature/product/types/DetailedProductType";
import React from "react";
import currencyService from "../../services/currencyService";

interface Props {
  product: DetailedProductType,
  value: number;
  onChange: (value: number) => void;
}

export const ProductPageWarranty: React.FC<Props> = (
  {
    product,
    value,
    onChange
  }
) => {

  return (
    <div className="mt-4">
      <h2 className="text-lg mt-2 text-gray-900 font-medium cursor-pointer flex items-center">
        Желаете ли допълнителна гаранция?
      </h2>

      <select className="mt-2 block w-full p-2 border border-gray-300 rounded-md focus:outline-none"
              onChange={(e) => onChange(parseInt(e.target.value))}>
        <option value="0">Без допълнителна гаранция</option>
        <option value="1">+1 година ({currencyService.format(product.additionalWarrantyPrice)})</option>
        <option value="2">+2 години ({currencyService.format(product.additionalWarrantyPrice * 2)})</option>
        <option value="3">+3 години ({currencyService.format(product.additionalWarrantyPrice * 3)})</option>
      </select>
    </div>
  )
}

export default ProductPageWarranty;