import {useCustomQuery} from "../../../lib/query";
import {QUERY_KEYS} from "../../../data/query-keys";
import productService from "../service/productService";

export const useGetOurOffers = () => {
  const {data, isLoading, isError} = useCustomQuery({
    key: QUERY_KEYS.GET_OUR_OFFERS,
    query: () => productService.getOurOffers(),
    keepPreviousData: true
  });

  if (isLoading || isError || !data) {
    return {isLoading, isError};
  }

  return {data, isLoading, isError};
}