import {useCustomQuery} from "../../../lib/query";
import {QUERY_KEYS} from "../../../data/query-keys";
import cartService from "../service/cartService";

export const useGetCartDetails = () => {
  const {data, isLoading, isError, refetch} = useCustomQuery({
    key: QUERY_KEYS.GET_CART_DETAILS,
    query: () => cartService.cartDetails(),
  });

  if (isLoading || isError || !data) {
    return {isLoading, isError, refetch};
  }

  return {data, isLoading, isError, refetch};
}