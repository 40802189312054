import {QueryPros} from "../hooks/queryHooks";
import {useMutation, useQuery} from "react-query";
import {AxiosError} from "axios";
import {toastService} from "../services/toastService";

export const useCustomQuery = <T>({key, query, keepPreviousData, refetchOnMount}: QueryPros<T>) => {
  return useQuery<T>({
    queryKey: key,
    queryFn: query,
    keepPreviousData: keepPreviousData,
    refetchOnMount: refetchOnMount,
  });
};

export const useCustomMutation = <T>(request: () => Promise<T>, onSuccess?: (data: T) => void, onError?: (data: T) => void) => {
  const fetchData = async () => await request();
  return useMutation({
    mutationFn: fetchData,
    onSuccess: (data: T) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (error: T) => {
      if (onError) {
        onError(error);
      }

      if (error instanceof AxiosError) {
        const response = error.response;
        if (response) {
          const data = response.data;
          if (data) {
            if ('message' in data) {
              toastService.error(data.message)
            }
          }
        }
      }
    }
  });
};