import React from "react";
import {Link} from "react-router-dom";

interface Props {
  label: string;
  categories: any;
}

export const NavbarDropdown: React.FC<Props> = ({label, categories}) => {
  return (
    <div className="group">
      <button className="px-5 py-4 group-hover:bg-[#2fa7e0] group-hover:text-white text-md tracking-wide uppercase font-semibold rounded">
        {label}
      </button>
      <div className="hidden group-hover:flex flex-col absolute p-5 w-auto text-black duration-300 shadow-xl rounded bg-white">
        <div className="pb-5">
          <h2 className="text-xl font-semibold text-gray-600">{label}</h2>
        </div>
        <div className="grid">
          <div className="flex flex-col">
            {categories.map((e: any, index: number) => {
              return (
                <Link key={index} to={e.href} className="hover:underline hover:text-[#2fa7e0] mt-1">
                  {e.label}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
