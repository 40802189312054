import {DetailedProductType} from "../../feature/product/types/DetailedProductType";
import React from "react";

interface Props {
  product: DetailedProductType
}

export const ProductPageDetails: React.FC<Props> = ({product}) => {
  return (
    <div
      className={
        " text-base leading-normal text-gray-600 "
      }
      id="sect"
    >
      <table className="w-full">
        <tbody>
        {product.details.map((detail, index) => (
          <tr key={index}>
            <td className="py-2 font-semibold">{detail.name}</td>
            <td className="py-2">{detail.value}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
}

export default ProductPageDetails;