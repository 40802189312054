import React from 'react';

const LoanInfo: React.FC = () => {
  return (
    <div className="p-4">
      <h3 className="md:text-4xl font-black tracking-wider text-center pb-5">Купуване на изплащане"</h3>

      <h2 className="text-3xl font-bold mb-6">Ето как да го направиш:</h2>

      <div className="mb-8">
        <h3 className="text-2xl font-bold mb-2">Избери продукт от сайта</h3>
        <p className="text-lg mb-4">
          Избери продукт от нашия сайт. Натисни "Купи с tbi bank" и посочи срок на плащане.
          Наш служител от Центъра за обслужване на клиенти ще се свърже с теб, за да попълните необходимите данни.
        </p>
      </div>

      <div className="mb-8">
        <h3 className="text-2xl font-bold mb-2">След одобрение на заявката</h3>
        <p className="text-lg mb-4">
          Ще ти изпратим SMS и email със секретен линк, чрез който да прикачиш снимки на личната си карта и ще завършим идентификацията ти.
        </p>
      </div>

      <div className="mb-8">
        <h3 className="text-2xl font-bold mb-2">Остава да проверим снимките</h3>
        <p className="text-lg mb-4">
          После ти изпращаме линк с всички документи за подписване чрез SMS и на email.
          За да ти е по-лесно, наш служител отново ще се свърже с теб, за да подпишеш дистанционно.
          Ти трябва само да продиктуваш уникалния код, който си получил на SMS.
          А подписаният договор и останалите документи ще получиш на email.
        </p>
      </div>

      <div className="mb-8">
        <h3 className="text-2xl font-bold mb-2">За финал, остава да изчакаш доставката</h3>
        <p className="text-lg mb-4">
          За финал, остава да изчакаш доставката на покупката/ите си на посочения адрес в срок.
        </p>
      </div>

      <h2 className="text-3xl font-bold mb-6">Защо на изплащане? Ето защо:</h2>

      <ul className="list-disc pl-8 mb-8">
        <li className="text-lg mb-2">
          Пазаруваш изцяло онлайн от всяка точка, без ограничения от работно време и обикаляне на магазини или банкови клонове.
        </li>
        <li className="text-lg mb-2">
          Получаваш отговор на заявката си до минути.
        </li>
        <li className="text-lg mb-2">
          Данните ти са напълно защитени – процесът по събиране на данни и подписване е сигурен и преминава през специални линкове и уникален код.
          И всичко това се случва дистанционно и съобразено изцяло с GDPR-правилата.
          Искаме да е лесно и затова с tbi bank не се налага да инсталираш допълнителни приложения за подпис или да се правят допълнителни регистрации.
        </li>
        <li className="text-lg">
          Погасяваш вноската си, както ти е удобно онлайн/офлайн.
          Знаеш ли, че можеш да платиш месечната си вноска по 11 удобни начина, пазарувайки с tbi bank?
          А вече можеш да го направиш светкавично бързо отвсякъде през мобилното приложение tbi bank app.
        </li>
      </ul>

      <p className="text-lg">
        С tbi bank покупките ти са защитени.
        Не пропускайте при покупка на вноски с tbi bank да добавиш застраховка „Защита на покупката и стоката“.
        Важно е! В случай на пълна повреда, кражба, грабеж или други неблагоприятни събития,
        съобразно посочените условия на нашия застраховател, той може да покрие частично или изцяло стойността на стоката.
      </p>

    </div>
  );
};

export default LoanInfo;
